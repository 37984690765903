import 'normalize.css'
import React, { useEffect, useState } from 'react'
import { ThemeProvider } from 'styled-components'

import './global.css'
import Fallback from './components/Fallback'
import Footer from './components/Footer'
import Main from './components/Main'
import theme from './styled/theme'
import { browserDetect } from './utils/browserDetect'
import { featureDetects } from './utils/featureDetects'
import { useStore } from './store'

type PreflightStatus = 'checking' | 'ready' | 'unsupported'

function App() {
  const [preflightStatus, setPreflightStatus] = useState<PreflightStatus>(
    'checking'
  )
  // Store (global)
  const getControlScheme = useStore(state => state.getControlScheme)
  const setFeatures = useStore(state => state.setFeatures)

  useEffect(() => {
    // Check we're not using a blacklisted / unsupported browser
    const browserSupported = browserDetect()

    // Check for required feature support
    const features = featureDetects()
    const requiredFeatures = [
      features.webgl, // WebGL 1
    ]
    const hasRequiredFeatures = requiredFeatures.every(v => v === true)

    // Store feature detection results
    setFeatures(features)

    // Get current control scheme (touch | pointerLock)
    const controlScheme = getControlScheme()

    // Display main app if:
    // - were not using an unsupported browser
    // - required features are supported
    // - a valid control scheme is detected
    // .. otherwise, display fallback page
    setPreflightStatus(
      browserSupported && hasRequiredFeatures && controlScheme
        ? 'ready'
        : 'unsupported'
    )
  }, [])

  return (
    <ThemeProvider theme={theme}>
      {preflightStatus === 'ready' && <Main />}
      {preflightStatus === 'unsupported' && <Fallback />}
      <Footer />
    </ThemeProvider>
  )
}

export default App
