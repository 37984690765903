import React, { useState, useEffect } from 'react'
import * as THREE from 'three'

import { useStore } from '../../store'
import { useExhibitionStore } from '../../store/exhibition'
import ActiveArtwork from '../ActiveArtwork'
import ActiveIntersect from '../ActiveIntersect'
import BookCover from '../BookCover'
import BoxMotion from '../BoxMotion'
import ControlsClickEvents from '../ControlsClickEvents'
import ControlsPointerLock from '../ControlsPointerLock'
import ControlsTouch from '../ControlsTouch'
import Crosshair from '../Crosshair'
import Debug from '../Debug'
import DebugPanel from '../DebugPanel'
import Instructions from '../Instructions'
import KeyboardShortcuts from '../KeyboardShortcuts'
import SceneExhibition from '../../three-fibers/SceneExhibition'
import ScreenAbout from '../ScreenAbout'
import ScreenIntro from '../ScreenIntro'
import ScreenLock from '../ScreenLock'
import TouchSphereClose from '../TouchSphereClose'

type Props = {}

const Exhibition = (props: Props) => {
  const [assetsLoaded, setAssetsLoaded] = useState(false)

  // Store (global)
  const viewActive = useStore(state => state.viewActive)
  // Store (exhibition)
  const introComplete = useExhibitionStore(state => state.introComplete)
  const sceneTransition = useExhibitionStore(state => state.sceneTransition)
  const setActiveScene = useExhibitionStore(state => state.setActiveScene)
  const started = useExhibitionStore(state => state.started)

  useEffect(() => {
    // Mark as loaded when all THREE assets have preloaded
    // We add a slight delay to prevent an unwanted flash of the loading dialog, and to stagger rendering
    THREE.DefaultLoadingManager.onLoad = function () {
      setTimeout(() => {
        setAssetsLoaded(true)
      }, 1500)
    }
  }, [])

  return (
    <>
      <BoxMotion
        // framer-motion
        animate={{
          opacity: introComplete && assetsLoaded && !sceneTransition ? 1 : 0,
        }}
        initial={{
          opacity: 0,
        }}
        onAnimationComplete={() => {
          if (sceneTransition) {
            setActiveScene(sceneTransition)
          }
        }}
        transition={{
          ease: 'easeInOut',
          duration: 1,
        }}
        // styled-props
        position="fixed"
        size="100%"
      >
        <SceneExhibition />
      </BoxMotion>

      {/* Intro Screen */}
      <ScreenIntro assetsLoaded={assetsLoaded} />

      {/* 2D Book SVG */}
      <BookCover />

      {/* Control events */}
      <ControlsClickEvents />
      <ControlsPointerLock />
      <ControlsTouch />

      <TouchSphereClose />

      {viewActive === 'exhibition' && started && (
        <>
          <KeyboardShortcuts />
          <DebugPanel />
          <Instructions />
          <Debug />
          <Crosshair />
          <ScreenLock />
          <ScreenAbout />
          <ActiveArtwork />
          <ActiveIntersect />
        </>
      )}
    </>
  )
}

export default Exhibition
