// Based off https://github.com/mrdoob/three.js/blob/master/examples/jsm/controls/PointerLockControls.js

import { useCallback, useEffect } from 'react'

import { playerApi, usePlayerStore } from '../../store/player'

type Props = {}

const ControlsPointerLock = () => {
  // Store (player)
  const freeLook = usePlayerStore(state => state.freeLook)
  const pointerLocked = usePlayerStore(state => state.pointerLocked)
  const setPointerLocked = usePlayerStore(state => state.setPointerLocked)

  // Callbacks
  const handleMouseMove = useCallback(
    (e: MouseEvent) => {
      if (!freeLook || !pointerLocked) {
        return
      }

      playerApi.setState({
        lookDelta: {
          x: e.movementX,
          y: e.movementY,
        },
      })
    },
    [freeLook, pointerLocked]
  )

  const handlePointerlockChange = useCallback(() => {
    const isLocked = document.pointerLockElement === document.body

    setPointerLocked(isLocked)
  }, [])

  const handlePointerlockError = useCallback(() => {
    // TODO: handle pointer lock error
    console.log('Unable to pointer lock')
  }, [])

  useEffect(() => {
    document.addEventListener('mousemove', handleMouseMove)
    document.addEventListener('pointerlockchange', handlePointerlockChange)
    document.addEventListener('pointerlockerror', handlePointerlockError)

    return () => {
      document.removeEventListener('mousemove', handleMouseMove)
      document.removeEventListener('pointerlockchange', handlePointerlockChange)
      document.removeEventListener('pointerlockerror', handlePointerlockError)
    }
  })

  return null
}

export default ControlsPointerLock
