import { AnimatePresence } from 'framer-motion'
import React, { useEffect } from 'react'

import { useStore } from '../../store'
import { useExhibitionStore } from '../../store/exhibition'
import { playerApi } from '../../store/player'
import ArtworkDescription from '../ArtworkDescription'
import Box from '../Box'
import BoxMotion from '../BoxMotion'
import ImageCarousel from '../ImageCarousel'
import ScreenWrapper from '../ScreenWrapper'

type Props = {}

const ActiveArtwork = () => {
  // Store (global)
  const controlScheme = useStore(state => state.controlScheme)
  // Store (exhibition)
  const activeArtwork = useExhibitionStore(state => state.activeArtwork)
  const clearActiveArtwork = useExhibitionStore(
    state => state.clearActiveArtwork
  )

  const close = () => {
    clearActiveArtwork()
  }

  const handleCloseArtwork = () => {
    if (controlScheme === 'pointerLock') {
      document.body.requestPointerLock()
    } else {
      close()
    }
  }

  useEffect(() => {
    const unsubscribe = playerApi.subscribe(
      pointerLocked => {
        if (pointerLocked) {
          close()
        }
      },
      state => state.pointerLocked
    )

    return () => {
      unsubscribe()
    }
  }, [])

  return (
    <AnimatePresence>
      {activeArtwork && (
        <>
          <BoxMotion
            // framer-motion
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            initial={{ opacity: 0 }}
            transition={{
              damping: 300,
              mass: 1,
              stiffness: 100,
              type: 'spring',
            }}
            // styled props
            bg="black"
            height="100%"
            position="fixed"
            right={0}
            size="100%"
            top={0}
            zIndex="screen"
          >
            <ScreenWrapper
              display="flex"
              flexDirection="column"
              justifyContent="space-between"
              onClose={handleCloseArtwork}
              pb={6}
              pt={3}
              px={4}
            >
              {/* Image */}
              <Box
                display="flex"
                height="100%"
                my={[4, 4, 5, 5, 6]}
                width="100%"
              >
                <ImageCarousel images={activeArtwork?.detail?.images} />
              </Box>

              {/* Description */}
              <ArtworkDescription
                detail={activeArtwork.detail}
                variant="light"
              />
            </ScreenWrapper>
          </BoxMotion>
        </>
      )}
    </AnimatePresence>
  )
}

export default ActiveArtwork
