import React, { useEffect } from 'react'
import { useThree } from 'react-three-fiber'
import shallow from 'zustand/shallow'

import { usePlayerStore } from '../../store/player'
import ImageCylinder from '../ImageCylinder'

type Props = {}

const SceneExhibitionImageCylinder = (props: Props) => {
  const { camera } = useThree()

  const { playerHeight, setHeldInPlace } = usePlayerStore(
    state => ({
      playerHeight: state.height,
      setHeldInPlace: state.setHeldInPlace,
    }),
    shallow
  )

  useEffect(() => {
    // Reset camera position + orientation
    camera.position.x = 0
    camera.position.y = playerHeight
    camera.position.z = 0

    camera.lookAt(0, playerHeight, -1)

    // Lock movement
    setHeldInPlace(true)
  }, [])

  return (
    <>
      {/* Video sphere */}
      <ImageCylinder radius={60} />
    </>
  )
}

export default SceneExhibitionImageCylinder
