import { AnimatePresence } from 'framer-motion'
import React, { useEffect } from 'react'

import { useStore } from '../../store'
import { useExhibitionStore } from '../../store/exhibition'
import { playerApi } from '../../store/player'
import BoxMotion from '../BoxMotion'
import Button from '../Button'
import ScreenWrapper from '../ScreenWrapper'
import TextOutline from '../TextOutline'

type Props = {
  assetsLoaded: boolean
}

const ScreenIntro = (props: Props) => {
  const { assetsLoaded } = props

  // Store (global)
  const controlScheme = useStore(state => state.controlScheme)
  // Store (exhibition)
  const setStarted = useExhibitionStore(state => state.setStarted)
  const started = useExhibitionStore(state => state.started)

  // Callbacks
  const handleClick = () => {
    if (controlScheme === 'pointerLock') {
      document.body.requestPointerLock()
    } else {
      start()
    }
  }

  const start = () => {
    setStarted(true)
  }

  useEffect(() => {
    const unsubscribe = playerApi.subscribe(
      pointerLocked => {
        if (pointerLocked) {
          start()
        }
      },
      state => state.pointerLocked
    )

    return () => {
      unsubscribe()
    }
  }, [])

  return (
    <AnimatePresence>
      {!started && (
        <BoxMotion
          // framer-motion
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          initial={{ opacity: 0 }}
          // styled props
          position="fixed"
          zIndex="screen"
        >
          <ScreenWrapper bg="rgba(0, 0, 0, 0.2)" blurBackground={true}>
            <BoxMotion
              // framer-motion
              animate={{ opacity: 1, scale: 1 }}
              initial={{ opacity: 0, scale: 0.9 }}
              transition={{
                delay: 0.5,
                ease: 'easeInOut',
                duration: 2.5,
              }}
              // styled props
              fontSize={[1, 1, 2]}
              lineHeight="body"
              maxWidth={['400px', '520px', '740px']}
              mx="auto"
              px={[4, 4, 4]}
              textAlign="center"
            >
              <p>
                Welcome to <TextOutline>Must Should Could</TextOutline>, a
                digital space and exhibition exploring the boundaries and ideas
                behind creative teaching. You are about to enter a new realm,
                feel free to walk around{' '}
                {controlScheme === 'pointerLock' && 'using your keyboard.'}
                {controlScheme === 'touch' &&
                  'by pressing down firmly on your screen.'}
              </p>
              <p>
                The walls and space are designed to encourage you play and
                explore, don't be afraid to get close, click into and browse
                freely.
              </p>
              {controlScheme === 'pointerLock' && (
                <p>To unlock your pointer, press escape at anytime.</p>
              )}
              <Button disabled={!assetsLoaded} onClick={handleClick}>
                {assetsLoaded ? 'OK' : 'Loading...'}
              </Button>
            </BoxMotion>
          </ScreenWrapper>
        </BoxMotion>
      )}
    </AnimatePresence>
  )
}

export default ScreenIntro
