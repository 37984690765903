import { AnimatePresence } from 'framer-motion'
import React, { useEffect } from 'react'

import { useStore } from '../../store'
import { useExhibitionStore } from '../../store/exhibition'
import { playerApi, usePlayerStore } from '../../store/player'
import BoxMotion from '../BoxMotion'
import About from '../About'
import ScreenWrapper from '../ScreenWrapper'

type Props = {}

const ScreenAbout = (props: Props) => {
  // Store (global)
  const controlScheme = useStore(state => state.controlScheme)
  // Store (exhibition)
  const aboutVisible = useExhibitionStore(state => state.aboutVisible)
  const activeArtwork = useExhibitionStore(state => state.activeArtwork)
  const setAboutVisible = useExhibitionStore(state => state.setAboutVisible)
  // Store (player)
  const pointerLocked = usePlayerStore(state => state.pointerLocked)

  const close = () => {
    setAboutVisible(false)
  }

  const handleClose = () => {
    if (controlScheme === 'pointerLock') {
      document.body.requestPointerLock()
    } else {
      close()
    }
  }

  useEffect(() => {
    const unsubscribe = playerApi.subscribe(
      pointerLocked => {
        if (pointerLocked) {
          close()
        }
      },
      state => state.pointerLocked
    )

    return () => {
      unsubscribe()
    }
  }, [])

  const visible = !pointerLocked && !activeArtwork && aboutVisible

  return (
    <AnimatePresence>
      {visible && (
        <BoxMotion
          // framer-motion
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          initial={{ opacity: 0 }}
          // styled props
          position="fixed"
          zIndex="screen"
        >
          <ScreenWrapper
            bg="black"
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            onClose={handleClose}
            overflowY="auto"
            pb={5}
            pt={3}
            px={[2, 2, 3]}
          >
            <About />
          </ScreenWrapper>
        </BoxMotion>
      )}
    </AnimatePresence>
  )
}

export default ScreenAbout
