import React, { useMemo } from 'react'
import shuffle from 'lodash.shuffle'

import getFibonacciSpherePoints from '../../utils/getFibonacciSpherePoints'
import { useExhibitionStore } from '../../store/exhibition'
import ImagePlane from '../ImagePlane'

type Props = {
  radius: number
}

const ImageSphere = (props: Props) => {
  const { radius } = props

  // Store (exhibition)
  const sphereArtwork = useExhibitionStore(state => state.sphereArtwork)

  const { planes } = sphereArtwork

  // Generate _shuffled_ fibonacci sphere points
  const fibonacciSpherePoints = useMemo(() => {
    const numPlanes = sphereArtwork?.planes.length
    const spherePoints = getFibonacciSpherePoints(numPlanes, radius)
    return shuffle(spherePoints)
  }, [])

  return (
    <>
      {fibonacciSpherePoints &&
        fibonacciSpherePoints.map((point, index: number) => (
          <ImagePlane
            aspectRatio={planes[index].mesh.aspectRatio}
            image={planes[index].image}
            index={index}
            key={index}
            position={[point.x, point.y, point.z]}
          />
        ))}
    </>
  )
}

export default ImageSphere
