import React from 'react'

type Props = {}

const IconClose = (props: Props) => {
  return (
    <svg
      viewBox="0 0 30.73 30.73"
      style={{
        display: 'block',
        width: '100%',
        height: 'auto',
      }}
    >
      <g fill="white">
        <path d="M29.23,30.73a1.51,1.51,0,0,1-1.06-.44L.44,2.56A1.5,1.5,0,0,1,2.56.44L30.29,28.17a1.49,1.49,0,0,1,0,2.12A1.51,1.51,0,0,1,29.23,30.73Z" />
        <path d="M1.5,30.73a1.51,1.51,0,0,1-1.06-.44,1.49,1.49,0,0,1,0-2.12L28.17.44a1.5,1.5,0,0,1,2.12,2.12L2.56,30.29A1.51,1.51,0,0,1,1.5,30.73Z" />
      </g>
    </svg>
  )
}

export default IconClose
