import React, { useEffect, useRef } from 'react'
import { useThree, useFrame } from 'react-three-fiber'
import * as THREE from 'three'
import shallow from 'zustand/shallow'

import { useContentStore } from '../../store/content'
import { useExhibitionStore } from '../../store/exhibition'
import { usePlayerStore } from '../../store/player'
import { clamp, lerp } from '../../utils/interpolation'
import { ArtworkCylinder, ArtworkFrame, ArtworkVideo } from '../../types'
import Bookshelf from '../Bookshelf'
import Cone from '../Cone'
// import CylinderPlaceholder from '../CylinderPlaceholder'
import Globe from '../Globe'
import Frame from '../Frame'
import FrameWithAlpha from '../FrameWithAlpha'
import PointLight from '../PointLight'
import Printer from '../Printer'
import Walls from '../Walls'
import Cylinder from '../Cylinder'
import { useStore } from '../../store'

type Props = {}

const EXIT_DISTANCE_THRESHOLD = 25
const EXIT_POINT = new THREE.Vector3(-20, 0, 2)
const FOG_FAR_MAX = 240
const FOG_FAR_MIN = 2

const SceneExhibitionMainHall = (props: Props) => {
  // Store (global)
  const { setViewTransition, viewTransition } = useStore(
    state => ({
      setViewTransition: state.setViewTransition,
      viewTransition: state.viewTransition,
    }),
    shallow
  )
  // Store (content)
  const {
    booklet,
    cone,
    cylinders,
    globes,
    frames,
    postcards,
    printer,
  } = useContentStore(
    state => ({
      booklet: state.booklet,
      cone: state.cone,
      cylinders: state.cylinders,
      globes: state.globes,
      frames: state.frames,
      postcards: state.postcards,
      printer: state.printer,
    }),
    shallow
  )
  // Store (player)
  const {
    bookmarkedFocusPoint,
    bookmarkedPosition,
    setHeldInPlace,
  } = usePlayerStore(
    state => ({
      bookmarkedFocusPoint: state.bookmarkedFocusPoint,
      bookmarkedPosition: state.bookmarkedPosition,
      setHeldInPlace: state.setHeldInPlace,
    }),
    shallow
  )
  // Store (exhibition)
  const { hallInitialFocusPoint, hallInitialPosition } = useExhibitionStore(
    state => ({
      hallInitialFocusPoint: state.hallInitialFocusPoint,
      hallInitialPosition: state.hallInitialPosition,
    }),
    shallow
  )

  const { camera } = useThree()

  const fog = useRef<THREE.Fog>(null)

  useFrame(() => {
    if (viewTransition === 'books') {
      return
    }

    if (camera.position.x <= EXIT_POINT.x) {
      const distanceFromExit = camera.position
        .clone()
        .distanceToSquared(EXIT_POINT)

      const fogFarDistance = clamp(
        // prettier-ignore
        lerp(FOG_FAR_MAX, FOG_FAR_MIN, distanceFromExit / EXIT_DISTANCE_THRESHOLD),
        FOG_FAR_MIN,
        FOG_FAR_MAX
      )

      if (fog.current) {
        fog.current.far = fogFarDistance
      }

      // Transition to books view when user has passed minimum distance from exit
      if (
        viewTransition !== 'books' &&
        distanceFromExit >= EXIT_DISTANCE_THRESHOLD
      ) {
        // Transition to books view
        setViewTransition('books')

        // Reset position
        camera.position.fromArray(hallInitialPosition)
      }
    } else {
      if (fog.current) {
        fog.current.far = FOG_FAR_MAX
      }
    }
  })

  useEffect(() => {
    // Reset camera position + orientation

    // Restore previously bookmarked position and look orientation, if applicable.
    // Otherwise reset both.
    if (bookmarkedPosition && bookmarkedFocusPoint) {
      camera.position.x = bookmarkedPosition.x
      camera.position.y = bookmarkedPosition.y
      camera.position.z = bookmarkedPosition.z
      camera.lookAt(bookmarkedFocusPoint)
    } else {
      // Reset position and look towards interpretation panel
      camera.position.fromArray(hallInitialPosition)
      camera.lookAt(hallInitialFocusPoint)
    }

    // Unlock movement
    setHeldInPlace(false)
  }, [])

  useEffect(() => {
    if (viewTransition === 'exhibition') {
      // Reset position and look towards interpretation panel
      camera.position.fromArray(hallInitialPosition)
      camera.lookAt(hallInitialFocusPoint)
    }
  }, [viewTransition])

  return (
    <>
      {/* Fog */}
      {/* <fog args={[0x000000, 1, 200]} attach="fog" ref={fog} /> */}
      <fog args={[0x000000, 1, 200]} attach="fog" ref={fog} />

      {/* Lighting */}
      <ambientLight intensity={0.8} />
      {/* <PointLight intensity={0.2} position={[0, 4, 0]} /> */}
      <PointLight intensity={0.15} position={[13.25, 8, 15]} />
      <PointLight intensity={0.15} position={[5, 8, 13]} />
      <PointLight intensity={0.15} position={[-12, 8, 13]} />

      {/* Walls */}
      <Walls />

      {/* Globes */}
      {globes &&
        globes.map((globe: ArtworkVideo, index: number) => (
          <Globe index={index} key={index} {...globe} />
        ))}

      {/* Bookshelves (postcards) */}
      <Bookshelf
        position={[10.72, 1.93, 9.19]}
        rotation={[0, Math.PI, 0]}
        width={1.5}
      />
      <Bookshelf
        position={[10.72, 1.55, 9.19]}
        rotation={[0, Math.PI, 0]}
        width={1.5}
      />
      <Bookshelf
        position={[10.72, 1.17, 9.19]}
        rotation={[0, Math.PI, 0]}
        width={1.5}
      />
      <Bookshelf
        position={[10.72, 0.79, 9.19]}
        rotation={[0, Math.PI, 0]}
        width={1.5}
      />

      {/* Bookshelf (booklet) */}
      <Bookshelf position={[8.5, 1.3, -3.88]} width={0.6} />

      {/* <CylinderPlaceholder /> */}

      {/* Cylinders */}
      {cylinders &&
        cylinders.map((cylinder: ArtworkCylinder, index: number) => (
          <Cylinder key={index} {...cylinder} />
        ))}

      {/* Cone */}
      {cone && <Cone {...cone} />}

      {/* Postcards */}
      {postcards &&
        postcards.map((postcard: ArtworkFrame, index: number) => (
          <Frame key={index} {...postcard} />
        ))}

      {/* Printer */}
      {printer && <Printer {...printer} />}

      {/* Booklet */}
      {booklet && <Frame {...booklet} />}

      {/* Frames (wall-based artwork) */}
      {frames &&
        frames.map((frame: ArtworkFrame, index: number) => {
          if (frame.image.alphaMap) {
            return <FrameWithAlpha key={index} {...frame} />
          } else {
            return <Frame key={index} {...frame} />
          }
        })}
    </>
  )
}

export default SceneExhibitionMainHall
