import { useEmblaCarousel } from 'embla-carousel-react'
import React from 'react'
import styled from 'styled-components'

import Box from '../Box'
import ImageCarouselSlide from '../ImageCarouselSlide'

type Props = {
  images: {
    src: string
  }[]
}

const Container = styled(Box)`
  .embla-container {
    position: relative;
    height: 100%;
    width: 100%;
  }
`

const LeftHitArea = styled(Box)`
  position: absolute;
  width: 50%;
  height: 100%;
  left: 0;
  top: 0;

  cursor: url('/cursors/left.png') 0 0, auto; /* Legacy */
  cursor: -webkit-image-set(
        url('/cursors/left.png') 1x,
        url('/cursors/left@2x.png') 2x
      )
      21 16,
    pointer; /* Webkit */
`

const RightHitArea = styled(Box)`
  position: absolute;
  width: 50%;
  height: 100%;
  right: 0;
  top: 0;

  cursor: url('/cursors/right.png') 0 0, auto; /* Legacy */
  cursor: -webkit-image-set(
        url('/cursors/right.png') 1x,
        url('/cursors/right@2x.png') 2x
      )
      21 16,
    pointer; /* Webkit */
`

const SlideContainer = styled(Box)`
  display: flex;
  height: 100%;
`

const ImageCarousel = (props: Props) => {
  const { images } = props

  const hasMultipleImages = images.length > 1

  const [EmblaCarouselReact, embla] = useEmblaCarousel({
    draggable: hasMultipleImages,
    loop: true,
    speed: 100,
  })

  const handleClick = (e: any) => {
    e.stopPropagation()

    // Return early if no fine cursor is detected (e.g. on a touch device)
    if (!matchMedia('(pointer: fine)').matches) {
      return
    }

    // Check if a 'static click' is registered from embla (a click that doesn't have preceding drag interactions)
    if (!embla || !embla.clickAllowed()) {
      return
    }

    // Prevent wrapper close behaviour if carousel behaviour is needed
    // (e.g. if we need to display more than one image)
    if (embla) {
      // embla.scrollNext()

      if (e.clientX < window.innerWidth * 0.5) {
        // LHS
        embla.scrollPrev()
      } else {
        // RHS
        embla.scrollNext()
      }
    }
  }

  return (
    <Container
      onClick={hasMultipleImages ? handleClick : undefined}
      size="100%"
    >
      <EmblaCarouselReact className="embla-container">
        <SlideContainer>
          {images?.map((image, index) => (
            <ImageCarouselSlide key={index} src={image.src} />
          ))}
        </SlideContainer>

        {/* Custom cursor hit areas */}
        {hasMultipleImages && (
          <>
            <LeftHitArea />
            <RightHitArea />
          </>
        )}
      </EmblaCarouselReact>
    </Container>
  )
}

export default ImageCarousel
