import create from 'zustand'

import {
  ArtworkBook,
  ArtworkCone,
  ArtworkCylinder,
  ArtworkFrame,
  ArtworkPrinter,
  ArtworkVideo,
} from '../types'

type InitialState = {
  books: ArtworkBook[]
  booklet: ArtworkFrame
  cone: ArtworkCone
  cylinders: ArtworkCylinder[] // wall-based artwork (curved)
  frames: ArtworkFrame[] // wall-based artwork
  globes: ArtworkVideo[]
  postcards: ArtworkFrame[]
  printer: ArtworkPrinter
}

const EYE_LEVEL_SPHERE = 1.4
const EYE_LEVEL_FRAME = 1.6
const EXTRUDE_FRAME = 0.06

const initialState: InitialState = {
  // Books
  books: [
    // 2020 - Must Should Could
    {
      button: {
        label: 'More info',
        url: 'https://freelandsfoundation.co.uk/exhibition/must-should-could',
      },
      mesh: {
        aspectRatio: 786 / 1024,
        depth: 0.25,
        height: 4.5,
      },
      textures: {
        back: {
          aspectRatio: 786 / 1024,
          src: '/textures/books/2020/back_786x1024_q60_color.jpg',
        },
        front: {
          aspectRatio: 786 / 1024,
          src: '/textures/books/2020/front_786x1024_q60_color.jpg',
        },
        spine: {
          aspectRatio: 49 / 1024,
          src: '/textures/books/2020/spine_49x1024_q60_color.jpg',
        },
      },
      title: 'Must Should Could',
      year: 2020,
    },
    // 2019 - Resist
    {
      button: {
        label: 'More info',
        url: 'https://freelandsfoundation.co.uk/page/publications',
      },
      mesh: {
        aspectRatio: 757 / 1024,
        depth: 0.25,
        height: 4.5,
      },
      textures: {
        back: {
          aspectRatio: 757 / 1024,
          src: '/textures/books/2019/back_757x1024_q60_color.jpg',
        },
        front: {
          aspectRatio: 757 / 1024,
          src: '/textures/books/2019/front_757x1024_q60_color.jpg',
        },
        spine: {
          aspectRatio: 69 / 1024,
          src: '/textures/books/2019/spine_69x1024_q60_color.jpg',
        },
      },
      title: 'Resist',
      year: 2019,
    },
    // 2018 - Brink
    {
      button: {
        label: 'More info',
        url: 'https://freelandsfoundation.co.uk/page/publications',
      },
      mesh: {
        aspectRatio: 750 / 1024,
        depth: 0.25,
        height: 4.5,
      },
      textures: {
        back: {
          aspectRatio: 750 / 1024,
          src: '/textures/books/2018/back_750x1024_q60_color.jpg',
        },
        front: {
          aspectRatio: 750 / 1024,
          src: '/textures/books/2018/front_750x1024_q60_color.jpg',
        },
        spine: {
          aspectRatio: 48 / 1024,
          src: '/textures/books/2018/spine_48x1024_q60_color.jpg',
        },
      },
      title: 'Brink',
      year: 2018,
    },
    // 2017 - Issue 2
    {
      button: {
        label: 'More info',
        url: 'https://freelandsfoundation.co.uk/page/publications',
      },
      mesh: {
        aspectRatio: 1024 / 764,
        depth: 0.001,
        height: 3.5,
      },
      textures: {
        back: {
          aspectRatio: 1024 / 764,
          src: '/textures/books/2017/back_1024x764_q60_color.jpg',
        },
        front: {
          aspectRatio: 1024 / 764,
          src: '/textures/books/2017/front_1024x764_q60_color.jpg',
        },
        spine: {
          aspectRatio: 1 / 1,
          src: '/textures/books/2017/spine_1x1_q60_color.jpg',
        },
      },
      title: 'Issue 2',
      year: 2017,
    },
    // 2016 - A dog in the playground
    {
      button: {
        label: 'More info',
        url: 'https://freelandsfoundation.co.uk/page/publications',
      },
      mesh: {
        aspectRatio: 356 / 512,
        depth: 0.2,
        height: 4.5,
      },
      textures: {
        back: {
          aspectRatio: 356 / 512,
          src: '/textures/books/2016/back_356x512_q60_color.jpg',
        },
        front: {
          aspectRatio: 356 / 512,
          src: '/textures/books/2016/front_356x512_q60_color.jpg',
        },
        spine: {
          aspectRatio: 30 / 512,
          src: '/textures/books/2016/spine_30x512_q60_color.jpg',
        },
      },
      title: 'A Dog in the Playground',
      year: 2016,
    },
  ],
  // Booklet
  // 5
  booklet: {
    detail: {
      artist: 'Emma Close',
      caption: 'Booklet, 21 x 14.8cm',
      category: 'Instruction',
      images: [
        {
          src: '/artworks/05_emma_close/01_1024x1448_q60_detail.jpg',
        },
        {
          src: '/artworks/05_emma_close/02_2048x1448_q60_detail.jpg',
        },
        {
          src: '/artworks/05_emma_close/03_2048x1448_q60_detail.jpg',
        },
        {
          src: '/artworks/05_emma_close/04_1024x1448_q60_detail.jpg',
        },
      ],
    },
    image: {
      aspectRatio: 256 / 256,
      src: '/artworks/05_emma_close/01_256x256_q60_color.jpg',
    },
    mesh: {
      aspectRatio: 256 / 341,
      extrude: 0.01,
      height: 0.5,
      position: [8.5, 1.57, -3.795],
      rotation: [-Math.PI * 0.1, 0, 0],
    },
  },
  // Cone
  cone: {
    detail: {
      artist:
        'Lucy Bradley, Klara Choromanska, Isobel Harvey, Jazmyn Maher, Molly Skinner, Holly Stenhouse',
      caption: 'Digital Sculpture',
      category: 'Balance',
    },
    mesh: {
      position: [14, 2.25, 3],
    },
    planes: [
      {
        image: {
          aspectRatio: 512 / 512,
          src: '/artworks/03_lucy_bradley_et_al/01_512x512_q60_color.jpg',
        },
        mesh: {
          aspectRatio: 512 / 465,
        },
      },
      {
        image: {
          aspectRatio: 512 / 512,
          src: '/artworks/03_lucy_bradley_et_al/02_512x512_q60_color.jpg',
        },
        mesh: {
          aspectRatio: 512 / 449,
        },
      },
      {
        image: {
          aspectRatio: 512 / 512,
          src: '/artworks/03_lucy_bradley_et_al/03_512x512_q60_color.jpg',
        },
        mesh: {
          aspectRatio: 512 / 288,
        },
      },
      {
        image: {
          aspectRatio: 512 / 512,
          src: '/artworks/03_lucy_bradley_et_al/04_512x512_q60_color.jpg',
        },
        mesh: {
          aspectRatio: 512 / 388,
        },
      },
      {
        image: {
          aspectRatio: 512 / 512,
          src: '/artworks/03_lucy_bradley_et_al/05_512x512_q60_color.jpg',
        },
        mesh: {
          aspectRatio: 288 / 512,
        },
      },
      {
        image: {
          aspectRatio: 512 / 512,
          src: '/artworks/03_lucy_bradley_et_al/06_512x512_q60_color.jpg',
        },
        mesh: {
          aspectRatio: 373 / 512,
        },
      },
      {
        image: {
          aspectRatio: 512 / 512,
          src: '/artworks/03_lucy_bradley_et_al/07_512x512_q60_color.jpg',
        },
        mesh: {
          aspectRatio: 512 / 370,
        },
      },
      {
        image: {
          aspectRatio: 512 / 512,
          src: '/artworks/03_lucy_bradley_et_al/08_512x512_q60_color.jpg',
        },
        mesh: {
          aspectRatio: 512 / 393,
        },
      },
      {
        image: {
          aspectRatio: 512 / 512,
          src: '/artworks/03_lucy_bradley_et_al/09_512x512_q60_color.jpg',
        },
        mesh: {
          aspectRatio: 390 / 512,
        },
      },
      {
        image: {
          aspectRatio: 512 / 512,
          src: '/artworks/03_lucy_bradley_et_al/10_512x512_q60_color.jpg',
        },
        mesh: {
          aspectRatio: 411 / 512,
        },
      },
      {
        image: {
          aspectRatio: 512 / 512,
          src: '/artworks/03_lucy_bradley_et_al/11_512x512_q60_color.jpg',
        },
        mesh: {
          aspectRatio: 492 / 512,
        },
      },
      {
        image: {
          aspectRatio: 512 / 512,
          src: '/artworks/03_lucy_bradley_et_al/12_512x512_q60_color.jpg',
        },
        mesh: {
          aspectRatio: 389 / 512,
        },
      },
      {
        image: {
          aspectRatio: 512 / 512,
          src: '/artworks/03_lucy_bradley_et_al/13_512x512_q60_color.jpg',
        },
        mesh: {
          aspectRatio: 340 / 512,
        },
      },
    ],
  },
  cylinders: [
    /**
     * WALL M (curved)
     */
    // 17
    {
      detail: {
        artist: 'Jenelle Marfo',
        caption: 'Painted wall text',
        category: 'Relationships',
        images: [
          {
            src: '/artworks/17_jenelle_marfo/01_2048x512_detail.png',
          },
        ],
      },
      image: {
        aspectRatio: 2048 / 512,
        src: '/artworks/17_jenelle_marfo/01_2048x512_color.png',
        transparent: true,
      },
      mesh: {
        height: 2.0,
        position: [-3.584, 2.25, 18.948],
        radius: 10.155,
        thetaStart: 2.932,
        thetaLength: 0.6953,
      },
    },
  ],
  // Globes
  globes: [
    // 1
    {
      detail: {
        artist: 'Harifa Ahmed',
        caption: 'Animation, 00\' 23"',
        category: 'Performance',
      },
      mesh: {
        position: [14.0, EYE_LEVEL_SPHERE, 15],
      },
      video: {
        aspectRatio: 1280 / 720,
        src: '/artworks/01_harifa_ahmed/01_x265_720p_rf25.mp4',
      },
    },
    // 6
    {
      detail: {
        artist: 'Holly Coupland',
        caption: 'Film, 00\' 10"',
        category: 'Performance',
      },
      mesh: {
        position: [-12.5, EYE_LEVEL_SPHERE, 4.5],
      },
      video: {
        aspectRatio: 720 / 480,
        src: '/artworks/06_holly_coupland/01_x265_480p_rf25.mp4',
      },
    },
    // 7
    {
      detail: {
        artist: 'Katie Duke',
        caption: 'Film, 2\'02"',
        category: 'Performance',
      },
      mesh: {
        position: [9.314, EYE_LEVEL_SPHERE, 0.9],
      },
      video: {
        aspectRatio: 1280 / 720,
        src: '/artworks/07_katie_duke/01_x264_720p_rf25.mp4',
      },
    },
    // 10b
    {
      detail: {
        artist: 'Lisa Haslinger',
        caption: 'Film, 03\' 31"',
        category: 'Performance',
      },
      mesh: {
        position: [-6, EYE_LEVEL_SPHERE, 1],
      },
      video: {
        aspectRatio: 1280 / 720,
        src: '/artworks/10b_lisa_haslinger/01_x265_720p_rf25.mp4',
      },
    },
  ],
  // Frames
  frames: [
    /**
     * WALL A
     */
    // 19 (1/3)
    {
      detail: {
        artist: 'Helena Maxwell-Johnson',
        caption: 'Prints',
        category: 'Performance',
        images: [
          {
            src:
              '/artworks/19_helena_maxwell-johnson/01_733x512_q60_detail.jpg',
          },
        ],
      },
      image: {
        aspectRatio: 512 / 512,
        src: '/artworks/19_helena_maxwell-johnson/03_512x512_q60_color.jpg',
      },
      mesh: {
        aspectRatio: 703 / 512,
        extrude: EXTRUDE_FRAME,
        height: 0.89 * 1.5,
        position: [-18.58, EYE_LEVEL_FRAME, -6.37],
        rotation: [0, Math.PI / 2, 0],
      },
    },
    // 19 (2/3)
    {
      detail: {
        artist: 'Helena Maxwell-Johnson',
        caption: 'Prints',
        category: 'Performance',
        images: [
          {
            src:
              '/artworks/19_helena_maxwell-johnson/02_716x512_q60_detail.jpg',
          },
        ],
      },
      image: {
        aspectRatio: 512 / 512,
        src: '/artworks/19_helena_maxwell-johnson/02_512x512_q60_color.jpg',
      },
      mesh: {
        aspectRatio: 716 / 512,
        extrude: EXTRUDE_FRAME,
        height: 0.89 * 1.5,
        position: [-18.58, EYE_LEVEL_FRAME, -8.44],
        rotation: [0, Math.PI / 2, 0],
      },
    },
    // 19 (3/3)
    {
      detail: {
        artist: 'Helena Maxwell-Johnson',
        caption: 'Prints',
        category: 'Performance',
        images: [
          {
            src:
              '/artworks/19_helena_maxwell-johnson/01_733x512_q60_detail.jpg',
          },
        ],
      },
      image: {
        aspectRatio: 512 / 512,
        src: '/artworks/19_helena_maxwell-johnson/01_512x512_q60_color.jpg',
      },
      mesh: {
        aspectRatio: 733 / 512,
        extrude: EXTRUDE_FRAME,
        height: 0.89 * 1.5,
        position: [-18.58, EYE_LEVEL_FRAME, -10.57],
        rotation: [0, Math.PI / 2, 0],
      },
    },
    /**
     * WALL B
     */
    // 15 (1/2)
    {
      detail: {
        artist: 'Kerry McGranaghan',
        caption: 'Digital collage',
        category: 'Bureaucracy',
        images: [
          {
            src: '/artworks/15_kerry_mcgranaghan/01_362x512_q60_detail.jpg',
          },
        ],
      },
      image: {
        aspectRatio: 512 / 512,
        src: '/artworks/15_kerry_mcgranaghan/02_512x512_q60_color.jpg',
      },
      mesh: {
        aspectRatio: 362 / 512,
        extrude: EXTRUDE_FRAME,
        position: [-15.22, EYE_LEVEL_FRAME, -7.57],
        rotation: [0, -1.23, 0],
        height: 1.29 * 1.5,
      },
    },
    // 15 (2/2)
    {
      detail: {
        artist: 'Kerry McGranaghan',
        caption: 'Digital collage',
        category: 'Bureaucracy',
        images: [
          {
            src: '/artworks/15_kerry_mcgranaghan/01_362x512_q60_detail.jpg',
          },
        ],
      },
      image: {
        aspectRatio: 512 / 512,
        src: '/artworks/15_kerry_mcgranaghan/01_512x512_q60_color.jpg',
      },
      mesh: {
        aspectRatio: 362 / 512,
        extrude: EXTRUDE_FRAME,
        position: [-14.71, EYE_LEVEL_FRAME, -6.13],
        rotation: [0, -1.23, 0],
        height: 1.25 * 1.5,
      },
    },
    /**
     * WALL C
     */
    // 2
    {
      detail: {
        artist: 'Iqra Ahmed',
        caption: 'Photograph, 74.5 x 112.9cm',
        category: 'Effort',
        images: [
          {
            src: '/artworks/02_iqra_ahmed/01_768x1164_q60_detail.jpg',
          },
        ],
      },
      image: {
        aspectRatio: 1024 / 1024,
        src: '/artworks/02_iqra_ahmed/01_1024x1024_q60_color.jpg',
      },
      mesh: {
        aspectRatio: 768 / 1164,
        extrude: EXTRUDE_FRAME,
        height: 1.41 * 1.5,
        position: [-11.27, EYE_LEVEL_FRAME, -3.88],
      },
    },
    /**
     * WALL D
     */
    // 20
    {
      detail: {
        artist: 'Linnea Nilsson',
        caption: 'Digital photograph',
        category: 'Performance',
        images: [
          {
            src: '/artworks/20_linnea_nilsson/01_512x768_q60_detail.jpg',
          },
        ],
      },
      image: {
        aspectRatio: 512 / 512,
        src: '/artworks/20_linnea_nilsson/01_512x512_q60_color.jpg',
      },
      mesh: {
        aspectRatio: 512 / 768,
        extrude: EXTRUDE_FRAME,
        // position: [-7.65, EYE_LEVEL_FRAME, -6.6],
        position: [-8.07, EYE_LEVEL_FRAME, -5.4],
        rotation: [0, 1.23, 0],
        height: 1.69 * 1.5,
      },
    },
    // 14
    {
      detail: {
        artist: 'Rebecca Hurn',
        caption: 'Framed prints',
        category: 'Effort',
        images: [
          {
            src: '/artworks/14_rebecca_hurn/01_2048x1448_detail.jpg',
          },
        ],
      },
      image: {
        aspectRatio: 1024 / 1024,
        src: '/artworks/14_rebecca_hurn/01_1024x1024_color.jpg',
        transparent: true,
      },
      mesh: {
        aspectRatio: 1024 / 724,
        position: [-6.39, 1.8, -10.17],
        rotation: [0, 1.23, 0],
        height: 0.8 * 1.5,
      },
    },
    /**
     * WALL E
     */
    {
      detail: {
        artist: 'Lisa Haslinger',
        caption: 'Modroc, calico, embroidery thread',
        category: 'Performance',
        images: [
          {
            src: '/artworks/10a_lisa_haslinger/01_1024x1409_q60_detail.jpg',
          },
        ],
      },
      image: {
        alphaMap: '/artworks/10a_lisa_haslinger/01_512x512_q60_alpha.jpg',
        aspectRatio: 512 / 512,
        src: '/artworks/10a_lisa_haslinger/01_512x512_q60_color.jpg',
        transparent: true,
      },
      mesh: {
        aspectRatio: 512 / 704,
        position: [-4.022, EYE_LEVEL_FRAME, -6.14],
        rotation: [0, -1.57, 0],
        height: 0.73 * 1.5,
      },
    },
    {
      detail: {
        artist: 'Lisa Haslinger',
        caption: 'Modroc, calico, embroidery thread',
        category: 'Performance',
        images: [
          {
            src: '/artworks/10a_lisa_haslinger/02_1024x1561_q60_detail.jpg',
          },
        ],
      },
      image: {
        alphaMap: '/artworks/10a_lisa_haslinger/02_512x512_q60_alpha.jpg',
        aspectRatio: 512 / 512,
        src: '/artworks/10a_lisa_haslinger/02_512x512_q60_color.jpg',
        transparent: true,
      },
      mesh: {
        aspectRatio: 512 / 704,
        position: [-4.022, EYE_LEVEL_FRAME, -5.25],
        rotation: [0, -1.57, 0],
        height: 0.73 * 1.5,
      },
    },
    /**
     * WALL F
     */
    // 28 (1/3)
    {
      detail: {
        artist: 'Rachel Wilcock',
        caption: 'Digital photographs',
        category: 'Balance',
        images: [
          {
            src: '/artworks/28_rachel_wilcock/01_768x504_q60_detail.jpg',
          },
        ],
      },
      image: {
        aspectRatio: 512 / 512,
        src: '/artworks/28_rachel_wilcock/01_512x512_q60_color.jpg',
      },
      mesh: {
        aspectRatio: 768 / 504,
        extrude: EXTRUDE_FRAME,
        position: [-1.85, EYE_LEVEL_FRAME, -3.88],
        height: 0.57 * 1.5,
      },
    },
    // 28 (2/3)
    {
      detail: {
        artist: 'Rachel Wilcock',
        caption: 'Digital photographs',
        category: 'Balance',
        images: [
          {
            src: '/artworks/28_rachel_wilcock/02_768x512_q60_detail.jpg',
          },
        ],
      },
      image: {
        aspectRatio: 512 / 512,
        src: '/artworks/28_rachel_wilcock/02_512x512_q60_color.jpg',
      },
      mesh: {
        aspectRatio: 768 / 512,
        extrude: EXTRUDE_FRAME,
        position: [-0.4, EYE_LEVEL_FRAME, -3.88],
        height: 0.57 * 1.5,
      },
    },
    // 28 (3/3)
    {
      detail: {
        artist: 'Rachel Wilcock',
        caption: 'Digital photographs',
        category: 'Balance',
        images: [
          {
            src: '/artworks/28_rachel_wilcock/03_768x567_q60_detail.jpg',
          },
        ],
      },
      image: {
        aspectRatio: 512 / 512,
        src: '/artworks/28_rachel_wilcock/03_512x512_q60_color.jpg',
      },
      mesh: {
        aspectRatio: 768 / 567,
        extrude: EXTRUDE_FRAME,
        position: [1.02, EYE_LEVEL_FRAME, -3.88],
        height: 0.57 * 1.5,
      },
    },
    /**
     * WALL F-1
     */
    {
      detail: {
        artist: 'Rebecca Hudson',
        caption: 'Collage',
        category: 'Identity',
        images: [
          {
            src: '/artworks/12_rebecca_hudson/01_768x856_q60_detail.jpg',
          },
        ],
      },
      image: {
        aspectRatio: 512 / 512,
        src: '/artworks/12_rebecca_hudson/01_512x512_q60_color.jpg',
      },
      mesh: {
        aspectRatio: 768 / 856,
        extrude: EXTRUDE_FRAME,
        height: 0.63 * 1.5,
        position: [14.41, EYE_LEVEL_FRAME, -5.1],
        rotation: [0, -Math.PI * 0.5, 0],
      },
    },
    /**
     * WALL G
     */
    // 18
    {
      detail: {
        artist: 'Frances Marriott',
        caption:
          'Photograph, 21 x 29.7cm and accompanying text as an image the same size as the photo',
        category: 'Identity',
        images: [
          {
            src: '/artworks/18_frances_marriott/01_1448x1024_q60_detail.jpg',
          },
        ],
      },
      image: {
        aspectRatio: 1024 / 1024,
        src: '/artworks/18_frances_marriott/01_1024x1024_q60_color.jpg',
      },
      mesh: {
        aspectRatio: 1448 / 1024,
        extrude: EXTRUDE_FRAME,
        height: 1.12 * 1.5,
        position: [16.4, EYE_LEVEL_FRAME, -3.89],
      },
    },
    // 26
    {
      detail: {
        artist: 'Christy Waddell',
        caption: 'Painting',
        category: 'Balance',
        images: [
          {
            src: '/artworks/26_christy_waddell/01_768x1080_q60_detail.jpg',
          },
        ],
      },
      image: {
        aspectRatio: 256 / 256,
        src: '/artworks/26_christy_waddell/01_256x256_q60_color.jpg',
      },
      mesh: {
        aspectRatio: 768 / 1080,
        extrude: EXTRUDE_FRAME,
        height: 0.6 * 1.5,
        position: [21.23, EYE_LEVEL_FRAME, -3.88],
      },
    },
    /**
     * WALL H
     */
    {
      detail: {
        artist: 'Jordan Hughes',
        caption: 'Drawing, 59.4 × 84.1cm',
        category: 'Balance',
        images: [
          {
            src: '/artworks/13_jordan_hughes/01_1024x1437_q60_detail.jpg',
          },
        ],
      },
      image: {
        alphaMap: '/artworks/13_jordan_hughes/01_1024x1024_q60_alpha.jpg',
        aspectRatio: 1024 / 1024,
        src: '/artworks/13_jordan_hughes/01_1024x1024_q60_color.jpg',
        transparent: true,
      },
      mesh: {
        aspectRatio: 768 / 768,
        height: 3.7 * 1.5,
        position: [24.27, 3.28, 3.06], // slightly higher
        rotation: [0, -Math.PI * 0.5, 0],
      },
    },
    /**
     * WALL I
     */
    // 27 (1 of 3)
    {
      detail: {
        artist: 'Laura Wend',
        caption:
          'Graphite on discarded tissue taper found in the classroom, 21 x 15cm',
        category: 'Effort',
        images: [
          {
            src: '/artworks/27_laura_wend/01_1024x2104_q60_detail.jpg',
          },
        ],
      },
      image: {
        alphaMap: '/artworks/27_laura_wend/01_512x1024_q60_alpha.jpg',
        aspectRatio: 512 / 1024,
        src: '/artworks/27_laura_wend/01_512x1024_q60_color.jpg',
        transparent: true,
      },
      mesh: {
        aspectRatio: 512 / 1024,
        height: 1.2 * 1.5,
        position: [21.38, EYE_LEVEL_FRAME, 9.18],
        rotation: [0, Math.PI, 0],
      },
    },
    // 27 (2 of 3)
    {
      detail: {
        artist: 'Laura Wend',
        caption:
          'Graphite on discarded cartridge paper found in the classroom. 30 x 13cm',
        category: 'Effort',
        images: [
          {
            src: '/artworks/27_laura_wend/02_1280x1766_q60_detail.jpg',
          },
        ],
      },
      image: {
        alphaMap: '/artworks/27_laura_wend/02_512x1024_q60_alpha.jpg',
        aspectRatio: 512 / 1024,
        src: '/artworks/27_laura_wend/02_512x1024_q60_color.jpg',
        transparent: true,
      },
      mesh: {
        aspectRatio: 512 / 1024,
        height: 1.6 * 1.5,
        position: [19.95, EYE_LEVEL_FRAME, 9.18],
        rotation: [0, Math.PI, 0],
      },
    },
    // 27 (3 of 3)
    {
      detail: {
        artist: 'Laura Wend',
        caption:
          'Graphite on discarded cartridge paper found in the classroom, 30 x 13cm',
        category: 'Effort',
        images: [
          {
            src: '/artworks/27_laura_wend/03_1024x2136_q60_detail.jpg',
          },
        ],
      },
      image: {
        alphaMap: '/artworks/27_laura_wend/03_512x1024_q60_alpha.jpg',
        aspectRatio: 512 / 1024,
        src: '/artworks/27_laura_wend/03_512x1024_q60_color.jpg',
        transparent: true,
      },
      mesh: {
        aspectRatio: 512 / 1024,
        height: 1.2 * 1.5,
        position: [18.55, EYE_LEVEL_FRAME, 9.18],
        rotation: [0, Math.PI, 0],
      },
    },
    /**
     * WALL J
     */
    // 23
    {
      detail: {
        artist: 'Chloe Shirley',
        caption: 'Print',
        category: 'Relationships',
        images: [
          {
            src: '/artworks/23_shirley_chloe/01_768x985_q60_detail.jpg',
          },
        ],
      },
      image: {
        aspectRatio: 512 / 512,
        src: '/artworks/23_shirley_chloe/01_512x512_q60_color.jpg',
      },
      mesh: {
        aspectRatio: 768 / 985,
        // height: 2.42 * 1.5,
        height: 2.05 * 1.5,
        position: [16, 1.8, 12.5], // slightly higher
        rotation: [0, -Math.PI * 0.5, 0],
      },
    },
    /**
     * WALL K
     */
    // 22
    {
      detail: {
        artist: 'Anoushka Seewoogoolam',
        caption: 'Watercolour on cotton and cartridge paper 17 x 52cm',
        images: [
          {
            src:
              '/artworks/22_anoushka_seewoogoolam/01_768x2310_q60_detail.jpg',
          },
        ],
      },
      image: {
        aspectRatio: 512 / 512,
        src: '/artworks/22_anoushka_seewoogoolam/01_512x512_q60_color.jpg',
      },
      mesh: {
        aspectRatio: 768 / 2310,
        extrude: EXTRUDE_FRAME,
        height: 2.05 * 1.5,
        position: [12.1, 1.8, 12.5], // slightly higher
        rotation: [0, Math.PI * 0.5, 0],
      },
    },
    /**
     * WALL L
     */
    // 8
    {
      detail: {
        artist: 'Eppie Greenhalgh',
        caption: 'Monoprints, wax crayon and watercolour',
        category: 'Balance',
        images: [
          {
            src: '/artworks/08_eppie_greenhalgh/01_768x1093_q60_detail.jpg',
          },
        ],
      },
      image: {
        aspectRatio: 512 / 512,
        src: '/artworks/08_eppie_greenhalgh/01_512x512_q60_color.jpg',
      },
      mesh: {
        aspectRatio: 768 / 1093,
        height: 1.24 * 1.5,
        position: [7.68, EYE_LEVEL_FRAME, 9.18],
        rotation: [0, Math.PI, 0],
      },
    },
    /**
     * WALL M (curved)
     */
  ],
  // Postcards
  postcards: [
    // 11
    // (1 / 11 - portrait)
    {
      detail: {
        artist: 'Alice Horn',
        caption: 'Collection of postcards',
        category: 'Performance',
        images: [
          {
            src: '/artworks/11_alice_horn/01_1024x1536_q60_detail.jpg',
          },
        ],
      },
      image: {
        aspectRatio: 256 / 256,
        src: '/artworks/11_alice_horn/01_256x256_q60_color.jpg',
      },
      mesh: {
        aspectRatio: 128 / 192,
        extrude: 0.005,
        height: 0.3,
        position: [11.2, 2.1, 9.145],
        rotation: [Math.PI * 0.1, Math.PI, 0],
      },
    },
    // (2 / 11 - portrait)
    {
      detail: {
        artist: 'Alice Horn',
        caption: 'Collection of postcards',
        category: 'Performance',
        images: [
          {
            src: '/artworks/11_alice_horn/02_1024x1536_q60_detail.jpg',
          },
        ],
      },
      image: {
        aspectRatio: 256 / 256,
        src: '/artworks/11_alice_horn/02_256x256_q60_color.jpg',
      },
      mesh: {
        aspectRatio: 128 / 192,
        extrude: 0.005,
        height: 0.3,
        position: [10.7, 2.1, 9.145],
        rotation: [Math.PI * 0.1, Math.PI, 0],
      },
    },
    // (3 / 11 - portrait)
    {
      detail: {
        artist: 'Alice Horn',
        caption: 'Collection of postcards',
        category: 'Performance',
        images: [
          {
            src: '/artworks/11_alice_horn/03_1024x1536_q60_detail.jpg',
          },
        ],
      },
      image: {
        aspectRatio: 256 / 256,
        src: '/artworks/11_alice_horn/03_256x256_q60_color.jpg',
      },
      mesh: {
        aspectRatio: 128 / 192,
        extrude: 0.005,
        height: 0.3,
        position: [10.2, 2.1, 9.145],
        rotation: [Math.PI * 0.1, Math.PI, 0],
      },
    },
    // (4 / 11 - portrait)
    {
      detail: {
        artist: 'Alice Horn',
        caption: 'Collection of postcards',
        category: 'Performance',
        images: [
          {
            src: '/artworks/11_alice_horn/04_1024x1536_q60_detail.jpg',
          },
        ],
      },
      image: {
        aspectRatio: 256 / 256,
        src: '/artworks/11_alice_horn/04_256x256_q60_color.jpg',
      },
      mesh: {
        aspectRatio: 128 / 192,
        extrude: 0.005,
        height: 0.3,
        position: [11.2, 1.725, 9.145],
        rotation: [Math.PI * 0.1, Math.PI, 0],
      },
    },
    // (5 / 11 - portrait)
    {
      detail: {
        artist: 'Alice Horn',
        caption: 'Collection of postcards',
        category: 'Performance',
        images: [
          {
            src: '/artworks/11_alice_horn/05_1024x1536_q60_detail.jpg',
          },
        ],
      },
      image: {
        aspectRatio: 256 / 256,
        src: '/artworks/11_alice_horn/05_256x256_q60_color.jpg',
      },
      mesh: {
        aspectRatio: 128 / 192,
        extrude: 0.005,
        height: 0.3,
        position: [10.7, 1.725, 9.145],
        rotation: [Math.PI * 0.1, Math.PI, 0],
      },
    },
    // (6 / 11 - landscape)
    {
      detail: {
        artist: 'Alice Horn',
        caption: 'Collection of postcards',
        category: 'Performance',
        images: [
          {
            src: '/artworks/11_alice_horn/06_1536x1024_q60_detail.jpg',
          },
        ],
      },
      image: {
        aspectRatio: 256 / 256,
        src: '/artworks/11_alice_horn/06_256x256_q60_color.jpg',
      },
      mesh: {
        aspectRatio: 192 / 128,
        extrude: 0.005,
        height: 0.2,
        position: [10.2, 1.685, 9.145],
        rotation: [Math.PI * 0.1, Math.PI, 0],
      },
    },
    // (7 / 11 - landscape)
    {
      detail: {
        artist: 'Alice Horn',
        caption: 'Collection of postcards',
        category: 'Performance',
        images: [
          {
            src: '/artworks/11_alice_horn/07_1536x1024_q60_detail.jpg',
          },
        ],
      },
      image: {
        aspectRatio: 256 / 256,
        src: '/artworks/11_alice_horn/07_256x256_q60_color.jpg',
      },
      mesh: {
        aspectRatio: 192 / 128,
        extrude: 0.005,
        height: 0.2,
        position: [11.2, 1.296, 9.145],
        rotation: [Math.PI * 0.1, Math.PI, 0],
      },
    },
    // (8 / 11 - landscape)
    {
      detail: {
        artist: 'Alice Horn',
        caption: 'Collection of postcards',
        category: 'Performance',
        images: [
          {
            src: '/artworks/11_alice_horn/08_1536x1024_q60_detail.jpg',
          },
        ],
      },
      image: {
        aspectRatio: 256 / 256,
        src: '/artworks/11_alice_horn/08_256x256_q60_color.jpg',
      },
      mesh: {
        aspectRatio: 192 / 128,
        extrude: 0.005,
        height: 0.2,
        position: [10.7, 1.296, 9.145],
        rotation: [Math.PI * 0.1, Math.PI, 0],
      },
    },
    // (9 / 11 - landscape)
    {
      detail: {
        artist: 'Alice Horn',
        caption: 'Collection of postcards',
        category: 'Performance',
        images: [
          {
            src: '/artworks/11_alice_horn/09_1536x1024_q60_detail.jpg',
          },
        ],
      },
      image: {
        aspectRatio: 256 / 256,
        src: '/artworks/11_alice_horn/09_256x256_q60_color.jpg',
      },
      mesh: {
        aspectRatio: 192 / 128,
        extrude: 0.005,
        height: 0.2,
        position: [10.2, 1.296, 9.145],
        rotation: [Math.PI * 0.1, Math.PI, 0],
      },
    },
    // (10 / 11 - portrait)
    {
      detail: {
        artist: 'Alice Horn',
        caption: 'Collection of postcards',
        category: 'Performance',
        images: [
          {
            src: '/artworks/11_alice_horn/10_1024x1536_q60_detail.jpg',
          },
        ],
      },
      image: {
        aspectRatio: 256 / 256,
        src: '/artworks/11_alice_horn/10_256x256_q60_color.jpg',
      },
      mesh: {
        aspectRatio: 128 / 192,
        extrude: 0.005,
        height: 0.3,
        position: [11.2, 0.965, 9.145],
        rotation: [Math.PI * 0.1, Math.PI, 0],
      },
    },
    // (11 / 11 - portrait)
    {
      detail: {
        artist: 'Alice Horn',
        caption: 'Collection of postcards',
        category: 'Performance',
        images: [
          {
            src: '/artworks/11_alice_horn/11_1024x1536_q60_detail.jpg',
          },
        ],
      },
      image: {
        aspectRatio: 256 / 256,
        src: '/artworks/11_alice_horn/11_256x256_q60_color.jpg',
      },
      mesh: {
        aspectRatio: 128 / 192,
        extrude: 0.005,
        height: 0.3,
        position: [10.7, 0.965, 9.145],
        rotation: [Math.PI * 0.1, Math.PI, 0],
      },
    },
  ],
  // Printer
  printer: {
    // 10b
    detail: {
      artist: 'Anna Rogers',
      caption: 'Xerox scans, 63 x 29.7cm',
      category: 'Performance',
      images: [
        {
          src: '/artworks/21_anna_rogers/01_2414_768_q60_detail.jpg',
        },
      ],
    },
    image: {
      aspectRatio: 2048 / 1024,
      src: '/artworks/21_anna_rogers/01_2048_x1024_q60_color.jpg',
    },
    mesh: {
      aspectRatio: 2414 / 768,
      position: [4, -0.5, 2.5],
      rotation: [0, -Math.PI * 0.5, 0],
    },
  },
}

export const [useContentStore, contentApi] = create((set, get) => ({
  ...initialState,
}))
