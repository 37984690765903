import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useFrame } from 'react-three-fiber'
import { ReactThreeFiber } from 'react-three-fiber/three-types'
import * as THREE from 'three'
import shallow from 'zustand/shallow'

import { useExhibitionStore } from '../../store/exhibition'
import { ArtworkVideo } from '../../types'
import { clamp } from '../../utils/interpolation'

type Props = ReactThreeFiber.Object3DNode<THREE.Mesh, typeof THREE.Mesh> &
  ArtworkVideo & {
    index: number
  }

const Globe = (props: Props) => {
  const { detail, mesh: meshProps, index, video } = props

  const [highlighted, setHighlighted] = useState(false)

  const bobDistance = useMemo(() => 0.1 + Math.random() * 0.4, [])
  const maxAmplitude = useMemo(() => 0.05 + Math.random() * 0.1, [])
  const maxPeriod = useMemo(() => 0.5 + Math.random() * 2, [])

  // Store (exhibition)
  const { addToCollisionList, sphereColor } = useExhibitionStore(
    state => ({
      addToCollisionList: state.addToCollisionList,
      sphereColor: state.sphereColor,
    }),
    shallow
  )

  const materialShader = useRef<any>()
  const mesh = useRef<THREE.Mesh>()

  useFrame(state => {
    // Rotate and bob slightly
    if (mesh.current) {
      const time = state.clock.getElapsedTime()
      const offset = index * 10

      mesh.current.rotation.x += 0.005
      mesh.current.position.y =
        meshProps.position[1] +
        bobDistance * (0.5 * Math.sin(time + offset) + 1)
      mesh.current.rotation.z += 0.005
    }
  })

  const onBeforeCompile = (shader: any) => {
    const from = '#include <begin_vertex>'
    const to = `
      vec3 transformed = vec3(position);
      transformed.x = position.x + (amplitude * sin(period * position.y*5.0 + time));
      transformed.z = position.z + (amplitude * sin(period * position.y*5.0 + time));
    `

    shader.vertexShader =
      `
      uniform float amplitude;
      uniform float period;
      uniform float time;
    ` + shader.vertexShader
    shader.vertexShader = shader.vertexShader.replace(from, to)
    // Initial uniforms
    shader.uniforms.amplitude = {
      type: 'f',
      value: maxAmplitude,
    }
    shader.uniforms.period = {
      type: 'f',
      value: maxPeriod,
    }
    shader.uniforms.time = {
      type: 'f',
      value: 0,
    }

    materialShader.current = shader
  }

  useEffect(() => {
    // Add to collision table
    addToCollisionList(mesh?.current)
  }, [])

  useFrame(state => {
    const elapsed = state.clock.getElapsedTime()

    if (materialShader.current) {
      materialShader.current.uniforms.time.value = elapsed

      materialShader.current.uniforms.amplitude.value = clamp(
        materialShader.current.uniforms.amplitude.value +
          (highlighted ? -0.005 : 0.005),
        0.0,
        maxAmplitude
      )

      materialShader.current.uniforms.period.value = clamp(
        materialShader.current.uniforms.period.value +
          (highlighted ? -0.025 : 0.05),
        1.0,
        maxPeriod
      )
    }
  })

  return (
    <mesh
      castShadow={true}
      onPointerOver={e => setHighlighted(true)}
      onPointerOut={e => setHighlighted(false)}
      ref={mesh}
      userData={{
        action: 'enterVideoSphere',
        detail,
        video,
      }}
      {...meshProps}
    >
      <sphereBufferGeometry args={[1, 64, 64]} attach="geometry" />
      <meshStandardMaterial
        attach="material"
        color={sphereColor}
        onBeforeCompile={onBeforeCompile}
        roughness={0.3}
      />
    </mesh>
  )
}

export default Globe
