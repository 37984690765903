import React from 'react'

import { useExhibitionStore } from '../../store/exhibition'
import Box from '../Box'
import IconClose from '../IconClose'
import { useStore } from '../../store'

type Props = {}

const TouchSphereClose = (props: Props) => {
  // Store (global)
  const controlScheme = useStore(state => state.controlScheme)
  // Store (exhibition)
  const activeScene = useExhibitionStore(state => state.activeScene)
  const setSceneTransition = useExhibitionStore(
    state => state.setSceneTransition
  )

  const visible =
    ['imageCylinder', 'imageSphere', 'videoSphere'].includes(activeScene) &&
    controlScheme === 'touch'

  const handleClose = () => {
    // Transition to new scene
    setSceneTransition('mainHall')
  }

  if (!visible) {
    return null
  }

  return (
    <Box
      className="hover-opacity"
      onClick={handleClose}
      position="fixed"
      right={[3, 3, 4]}
      top={[3, 3, 4]}
      size={['20px', '20px', '30px']}
      zIndex="screen"
    >
      <IconClose />
    </Box>
  )
}

export default TouchSphereClose
