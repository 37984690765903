import React, { useRef, Suspense } from 'react'
import { Canvas, extend, useFrame, useThree } from 'react-three-fiber'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls'

import { ArtworkBook } from '../../types'
import Book from '../Book'

extend({ OrbitControls })

type Props = ArtworkBook & {}

const Controls = () => {
  const controls = useRef<OrbitControls>(null)
  const { camera, gl } = useThree()

  useFrame(() => controls.current?.update())

  return (
    <orbitControls
      autoRotate
      autoRotateSpeed={1}
      ref={controls}
      args={[camera, gl.domElement]}
      enableDamping
      enablePan={false}
      enableZoom={false}
      dampingFactor={0.1}
      maxPolarAngle={Math.PI * 0.9}
      minPolarAngle={Math.PI * 0.1}
      rotateSpeed={1.2}
    />
  )
}

const SceneBook = (props: Props) => {
  return (
    <Canvas colorManagement={true} pixelRatio={1}>
      <Suspense fallback={null}>
        <Book {...props} />
      </Suspense>

      <Controls />
    </Canvas>
  )
}

export default SceneBook
