import { useFrame, useThree } from 'react-three-fiber'

import { useStore } from '../../store'
import { exhibitionApi, useExhibitionStore } from '../../store/exhibition'

type Props = {}

const CrosshairRay = (props: Props) => {
  const viewActive = useStore(state => state.viewActive)
  // Store (exhibition)
  const activeScene = useExhibitionStore(state => state.activeScene)
  const collisionList = useExhibitionStore(state => state.collisionList)
  const introComplete = useExhibitionStore(state => state.introComplete)
  const setIntersectingObject = useExhibitionStore(
    state => state.setIntersectingObject
  )
  const started = useExhibitionStore(state => state.started)

  const { raycaster: defaultRaycaster } = useThree()

  const active =
    viewActive === 'exhibition' &&
    activeScene === 'mainHall' &&
    introComplete &&
    started

  useFrame(state => {
    const { intersect } = state

    if (!active) {
      return
    }

    // TODO: type correctly
    const intersects = defaultRaycaster.intersectObjects(collisionList)

    // TODO: document - update R3f's internal raycaster
    intersect()

    const previousIntersect = exhibitionApi.getState().intersectingObject

    // FIXME: let's clean this up...
    if (intersects.length > 0) {
      const intersect = intersects[0]
      const intersectAction = intersect?.object?.userData?.action
      const intersectUuid = intersect?.object?.uuid

      // If the intersecting object has an action? ('artworkFrame', 'wall' etc), update state
      // - otherwise clear.
      if (intersectAction) {
        // TODO: reconsider usage of `userData`
        if (previousIntersect?.uuid !== intersectUuid) {
          setIntersectingObject({
            ...intersect.object.userData,
            face: intersect.face,
            point: intersect.point,
            uuid: intersectUuid,
          })
        }
      } else {
        if (previousIntersect) {
          setIntersectingObject(undefined)
        }
      }
    } else {
      if (previousIntersect) {
        setIntersectingObject(undefined)
      }
    }
  })

  return null
}

export default CrosshairRay
