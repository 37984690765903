import React, { useEffect, useMemo, useRef, useState } from 'react'
import * as THREE from 'three'

import { useExhibitionStore } from '../../store/exhibition'
import getFibonacciSpherePoints from '../../utils/getFibonacciSpherePoints'
import VideoPlane from '../VideoPlane'

type Props = {
  radius: number
}

const INSTANCE_COUNT = 18

const VideoSphere = (props: Props) => {
  const { radius } = props
  const [videoReady, setVideoReady] = useState(false)

  // Store (exhibition)
  const clearActiveVideo = useExhibitionStore(state => state.clearActiveVideo)
  const setActiveVideo = useExhibitionStore(state => state.setActiveVideo)
  const setActiveVideoPlaying = useExhibitionStore(
    state => state.setActiveVideoPlaying
  )
  const sphereArtwork = useExhibitionStore(state => state.sphereArtwork)

  const fibonacciSpherePoints = useMemo(
    () => getFibonacciSpherePoints(INSTANCE_COUNT, radius),
    []
  )
  const videoTexture = useRef<THREE.VideoTexture>()
  const mesh = useRef<THREE.Mesh>()
  const geom = useMemo(() => new THREE.SphereBufferGeometry(radius, 8, 8), [])

  const handleCanPlay = () => setVideoReady(true)
  const handlePause = () => setActiveVideoPlaying(false)
  const handlePlaying = () => setActiveVideoPlaying(true)

  useEffect(() => {
    // Create our video element + video texture
    const video = document.createElement('video')
    video.autoplay = true
    video.loop = true
    // video.muted = true
    video.setAttribute('playsinline', '')
    video.src = sphereArtwork?.video?.src
    video.addEventListener('canplay', handleCanPlay)
    video.addEventListener('pause', handlePause)
    video.addEventListener('playing', handlePlaying)

    videoTexture.current = new THREE.VideoTexture(video)
    videoTexture.current.minFilter = THREE.LinearFilter
    videoTexture.current.magFilter = THREE.LinearFilter
    videoTexture.current.format = THREE.RGBFormat

    // Store reference to video
    setActiveVideo(video)

    // Assign shared geometry
    if (mesh.current) {
      mesh.current.geometry = geom
    }

    // Unload video on unmount
    return () => {
      if (video) {
        video.removeEventListener('canplay', handleCanPlay)
        video.removeEventListener('pause', handlePause)
        video.removeEventListener('playing', handlePlaying)
        video.pause()
        video.src = ''
        video.load()

        // Clear reference to current video
        clearActiveVideo()
      }
    }
  }, [])

  return (
    <>
      {/* Black sphere */}
      <mesh ref={mesh} scale={[1.2, 1.2, 1.2]}>
        <meshBasicMaterial
          attach="material"
          color={0x000000}
          side={THREE.BackSide}
        />
      </mesh>

      {/* Wireframe */}
      {/*
      <lineSegments>
        <edgesGeometry attach="geometry" args={[geom]} />
        <lineBasicMaterial attach="material" color={0x222222} />
      </lineSegments>
      */}

      {videoReady &&
        fibonacciSpherePoints &&
        fibonacciSpherePoints.map((point, index) => (
          <VideoPlane
            index={index}
            key={index}
            position={[point.x, point.y, point.z]}
            texture={videoTexture.current}
          />
        ))}
    </>
  )
}

export default VideoSphere
