// Remember that em units in media queries are always relative to 16px / the user setting
export default {
  // Remember that em units in media queries are always relative to 16px / the user setting
  // and NOT the html font size!
  // This means generally speaking:
  breakpoints: [
    '32em', // 32em = 512px
    '48em', // 48em = 768px
    '64em', // 64em = 1024px
    '80em', // 80em = 1280px
    '96em', // 96em = 1536px
  ],
  colors: {
    // grayscale
    black: '#000000',
    lightGray: '#aaa',
    white: '#ffffff',
    // brand
    red: '#f05159',
    blue: '#355888',
    yellow: '#ffda38',
  },
  fonts: {
    sans: 'Proto Grotesk, Helvetica, Arial, sans-serif',
    system:
      '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
  },
  // 1.250 / major third (type-scale.com)
  fontSizes: [
    '0.8rem', //
    '1.0rem',
    '1.25rem',
    '1.563rem',
    '1.953rem',
    '2.441rem',
    '3.052rem',
    '3.815rem',
  ],
  fontWeights: {
    light: 300,
    bold: 600,
  },
  letterSpacings: {
    tight: '-0.01em',
    body: '0.005em',
    heading: '0.005em',
  },
  lineHeights: {
    default: '1.1em',
    body: '1.25em',
  },
  // Used for borders
  radii: {
    base: '0.25em',
    pill: '1em',
  },
  sizes: [],
  space: [
    0, //
    '4px',
    '8px',
    '16px',
    '24px',
    '48px',
    '72px',
    '96px',
    '192px',
  ],
  zIndices: {
    debug: 9000,
    footer: 40,
    screen: 30,
    crosshair: 26,
    instructions: 25,
  },
}
