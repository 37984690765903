import React from 'react'
import styled from 'styled-components'

import useImageLoaded from '../../hooks/useImageLoaded'
import Box from '../Box'
import BoxMotion from '../BoxMotion'
import ImageBackground from '../ImageBackground'

type Props = {
  src: string
}

const Slide = styled(Box)`
  position: relative; /* Required for embla-carousel */
  flex: 0 0 100%;
`

const ImageCarouselSlide = (props: Props) => {
  const { src } = props

  const loaded = useImageLoaded(src)

  return (
    <Slide>
      <BoxMotion
        // framer-motion
        animate={{
          opacity: loaded ? 1 : 0,
        }}
        // styled props
        size="100%"
      >
        <ImageBackground src={src} />
      </BoxMotion>
    </Slide>
  )
}

export default ImageCarouselSlide
