import React from 'react'
import * as THREE from 'three'
import shallow from 'zustand/shallow'

import { useExhibitionStore } from '../../store/exhibition'
import Box from '../Box'

type Props = {}

const Debug = (props: Props) => {
  const { debugPanel, intersectingObject } = useExhibitionStore(
    state => ({
      debugPanel: state.debugPanel,
      intersectingObject: state.intersectingObject,
    }),
    shallow
  )

  if (!debugPanel) {
    return null
  }

  const intersectingObjectAction = intersectingObject?.action

  let intersectInfo
  if (intersectingObjectAction === 'wall') {
    const normal = intersectingObject.face.normal
    const forward = new THREE.Vector3(0, 0, 1)
    const rotationY = forward.angleTo(normal).toFixed(2)

    // prettier-ignore
    intersectInfo = `[${intersectingObject.point.x.toFixed(2)}, ${intersectingObject.point.y.toFixed(2)}, ${intersectingObject.point.z.toFixed(2)}] rY: ${rotationY}`
  }

  return (
    <Box
      bg="white"
      color="black"
      fontFamily="system"
      fontSize={0}
      left={0}
      p={1}
      position="fixed"
      top={0}
      zIndex="debug"
    >
      {intersectInfo && <Box>ray: {intersectInfo}</Box>}
    </Box>
  )
}

export default Debug
