import React from 'react'
import styled from 'styled-components'

import { BoxProps } from '../../types'
import Box from '../Box'

type Props = BoxProps & {}

const Container = styled(Box)`
  svg {
    width: 100%;
    height: auto;
  }
`

const LogoFF = (props: Props) => {
  const { ...boxProps } = props

  return (
    <Container className="hover-opacity" color="white" {...boxProps}>
      <a
        href="https://www.freelandsfoundation.co.uk/"
        rel="noopener noreferrer"
        target="_blank"
      >
        <svg viewBox="0 0 515 132.7">
          <g fill="white">
            <polygon points="146.6,58.9 154.4,58.9 154.4,31.2 182.3,31.2 182.3,24.4 154.4,24.4 154.4,7 184.4,7 184.4,0 146.6,0 " />
            <path
              d="M195.3,40.7c-0.1-3.8,0-5.3,0.6-7.3c1-3.8,4-6.5,7.9-7.2c1.2-0.2,2.5-0.3,4.9,0v-7.2c-5.1,0-8.2,1.8-9.8,3.2
	c-1.6,1.2-2.8,2.8-3.7,4.5v-7.2h-6.7v39.3h6.7V40.7z"
            />
            <path
              d="M245.4,52.4c1.5-2.3,2.3-4.7,2.6-5.5l-6.8-0.9c-0.2,0.5-0.6,2.4-1.6,4.2c-0.8,1.3-1.8,2.3-2.2,2.6c-1.2,1.1-3,2.4-6.6,2.4
	c-2.5,0-5.1-0.7-7.1-2c-2.4-1.7-5.8-5.7-5.7-12.7l29.9-0.1c0-5.9-0.8-9-1.9-11.6c-2.7-6.2-7.5-8.5-9.9-9.3c-1.8-0.7-4.2-1.1-6.2-1.1
	c-5.6,0-11,2.4-14.6,6.8c-2,2.4-4.4,6.5-4.4,13.8c0,12.3,6.2,17.6,11,19.7c1.5,0.7,4.2,1.6,8.3,1.6
	C239.1,60.1,243.4,55.4,245.4,52.4z M229.5,23.5c5.2,0,9.3,3.4,10.7,8.3c0.3,0.9,0.4,1.8,0.6,3.8h-22.4
	C218.9,29.4,222.4,23.5,229.5,23.5z"
            />
            <path
              d="M287.4,52.4c1.5-2.3,2.3-4.7,2.6-5.5l-6.8-0.9c-0.2,0.5-0.6,2.4-1.6,4.2c-0.8,1.3-1.8,2.3-2.2,2.6c-1.2,1.1-3,2.4-6.6,2.4
	c-2.5,0-5.1-0.7-7.1-2c-2.4-1.7-5.8-5.7-5.7-12.7l29.9-0.1c0-5.9-0.8-9-1.9-11.6c-2.7-6.2-7.5-8.5-9.9-9.3c-1.8-0.7-4.2-1.1-6.2-1.1
	c-5.6,0-11,2.4-14.6,6.8c-2,2.4-4.4,6.5-4.4,13.8c0,12.3,6.2,17.6,11,19.7c1.5,0.7,4.2,1.6,8.3,1.6
	C281.1,60.1,285.4,55.4,287.4,52.4z M271.5,23.5c5.2,0,9.3,3.4,10.7,8.3c0.3,0.9,0.4,1.8,0.6,3.8h-22.4
	C260.9,29.4,264.4,23.5,271.5,23.5z"
            />
            <rect x="295.7" y="0" width="6.3" height="58.9" />
            <path
              d="M322.5,36.6c-1.2,0.3-5.9,0.8-9.6,3.6c-3.8,2.9-4.3,6.7-4.3,8.5c0,5.1,3.3,8.8,7.9,10.4c1,0.3,2.8,0.8,5.4,0.8
	c7.6,0,11.6-4.4,13.4-6.4c0.2,0.8,0.2,1.8,1.3,3.3c0.2,0.2,0.7,1,1.3,1.5c1.5,1,3.8,1,4.2,1c1.1,0,2.3-0.1,4.3-0.8V53
	c-0.8,0.2-1.2,0.3-2,0.3c-2.8,0-2.8-2.1-2.9-3c-0.1-0.8-0.1-1.8-0.1-2.6V35c0-4.3-0.1-6.3-0.8-8.4c-1.9-5.3-7.2-6.9-8.8-7.3
	c-2.8-0.8-5-0.8-6.2-0.8c-9.5,0-13.2,5.3-14.4,9.1c-0.5,1.7-0.7,3.1-0.7,3.8l6.9,0.4c0-1.3,0-2.3,0.4-3.5c1.6-4.8,6.8-4.8,8.1-4.8
	c8,0,8.5,5.8,8.7,8.7c0.1,1.1,0.1,2.2,0.1,3.3c-1.9,0.3-4,0.3-6,0.4C326.7,36,324.6,36.3,322.5,36.6z M334.9,39.7
	c0,1,0.1,2.1,0.1,3.2c0,1.6,0.1,6-3.8,9.1c-2.9,2.4-6.4,2.4-7.7,2.4c-3.7,0-5.6-1.4-6.5-2.8c-0.3-0.5-0.9-1.5-0.9-3.1
	c0-5.1,5.9-6.4,6.6-6.6c0.9-0.3,1.8-0.4,2.8-0.6c1.3-0.3,2.6-0.6,3.9-0.8C331.1,40.2,333,40,334.9,39.7z"
            />
            <path
              d="M359.5,36.5c0-1.9,0-3.6,0.8-5.4c0.7-1.8,1.8-3.1,2.3-3.6c2.1-2,4.4-2.9,7.3-2.9c3.1,0,4.9,1,5.9,1.8c3.3,2.5,3,6.3,3,7.5
	v25h6.6V35.5c0-4.3,0.2-8.1-2.4-11.7c-3.5-4.9-9.4-5.4-11.2-5.4c-5.4,0-8.8,2.8-10.3,4.3c-0.4,0.4-1.3,1.3-2.1,2.8v-5.8h-6.7v39.3
	h6.7V36.5z"
            />
            <path
              d="M423.7,24.8c-0.8-1-1.3-1.7-2.3-2.5c-1.7-1.3-5.6-3.9-11.2-3.9c-7.9,0-12.3,4.6-14,7c-3.1,4.1-4,8.8-4,13.8
	c0,7.9,2.6,12.4,4.8,15.1c1.9,2.3,5.8,5.9,13.3,5.9c7.8,0,11.4-3.9,13.4-6.3v5h6.7V0h-6.7V24.8z M418,52.5c-1.8,1.2-4,1.7-6.3,1.7
	c-1.9,0-6.1-0.2-9.3-4.3c-3.1-4.1-3-9.1-3-10.6c0-4,0.7-7.7,3.3-10.8c3.2-3.8,7.3-4.1,9.1-4.1c5.9,0.1,10.3,4,11.6,9.7
	c0.5,2.1,0.5,4.3,0.5,5.1C424,46.7,420.9,50.7,418,52.5z"
            />
            <path
              d="M462.8,52.8c-2.6,2.3-6.5,2.3-7.3,2.3c-3.3,0-6.4-0.4-8.8-3c-1.9-2.1-2.2-4.3-2.2-6.9l-7.6,1.1c0.3,1.5,0.8,5.2,3.6,8.6
	c1.2,1.4,2.2,2.1,2.8,2.5c4.3,2.8,10.5,2.8,11.7,2.8c8.3,0,12.8-3.4,14.8-6.5c1.5-2.4,1.8-4.9,1.8-5.9c0-6-4.3-8.8-9.5-10.4
	c-1.2-0.3-2.4-0.7-3.6-1l-3.8-0.9c-4.7-1.1-7.7-2-8.9-4.1c-0.5-0.9-0.6-1.6-0.6-2.1c0-2.5,2.3-5.8,7.8-5.8c5.7,0,8.9,2.8,9.9,5
	c0.4,0.9,0.5,1.8,0.6,3.7l7.2-0.8c-0.2-1.1-0.5-3.6-2.1-6c-1.9-2.8-6.4-6.9-14.7-6.9c-1.4,0-9.2,0-13.2,5.3
	c-1.9,2.6-2.1,5.3-2.1,6.4c0,3.8,1.6,6.4,4.8,8.3c2.1,1.3,4.3,2,9.3,3.1l3.8,0.8c2.2,0.4,6.1,1.1,7.4,3.3c0.8,1.1,0.8,2.5,0.8,2.9
	C464.7,49.4,464.6,51.2,462.8,52.8z"
            />
            <path
              d="M214.6,92.4c-2.3-1-5.2-1.5-7.8-1.5c-6.2,0-11.3,1.9-15.1,6.8c-3.2,4.1-4.5,9.2-4.5,14.3c0,8.3,3.3,13,5.8,15.5
	c4.8,4.9,11.3,5.2,13.6,5.2c8.2,0,12.4-4,14.6-6.6c4.3-5.4,4.6-12.1,4.6-14.3C225.8,99.9,219.2,94.3,214.6,92.4z M218.5,111.8
	c-0.1,0.8-0.2,6.9-2.7,10.7c-3,4.5-7.5,4.8-9.3,4.9c-9.3,0-11.3-8.3-11.8-11.1c-0.1-0.6-0.3-2.1-0.3-4c0-2.3-0.1-16,12.5-16
	c1.3,0,4.3,0.1,7.2,2.5c0.9,0.8,1.8,1.9,2.4,3C218.6,105.6,218.5,110.7,218.5,111.8z"
            />
            <path
              d="M257.3,111.8c-0.1,3.4,0.2,6.8-1.9,9.8c-2,3.1-5.7,4.5-9.3,4.5c-4.3,0-6.4-2.1-7.2-3.8c-1-1.9-0.9-3.8-0.9-7.9V92.2h-6.6v23
	c0,3.8-0.3,8.1,1.8,11.7c2.2,3.9,6.4,5.6,10.7,5.6c6.2,0,9.9-3.2,11.6-4.8c0.6-0.7,1.3-1.6,1.8-2.3l0.1,6h6.5V92.2h-6.5V111.8z"
            />
            <path
              d="M290.9,90.9c-5.4,0-8.8,2.8-10.3,4.3c-0.4,0.4-1.3,1.3-2.1,2.8v-5.8h-6.7v39.3h6.7v-22.3c0-1.9,0-3.6,0.8-5.4
	c0.7-1.8,1.8-3.1,2.3-3.6c2.1-2,4.4-2.9,7.3-2.9c3.1,0,4.9,1,5.9,1.8c3.3,2.5,3,6.3,3,7.5v25h6.6V108c0-4.3,0.2-8.1-2.4-11.7
	C298.6,91.4,292.7,90.9,290.9,90.9z"
            />
            <path
              d="M342.4,97.3c-0.8-1-1.3-1.7-2.3-2.5c-1.7-1.3-5.6-3.9-11.2-3.9c-7.9,0-12.3,4.6-14,7c-3.1,4.1-4,8.8-4,13.8
	c0,7.9,2.6,12.4,4.8,15.1c1.9,2.3,5.8,5.9,13.3,5.9c7.8,0,11.4-3.9,13.4-6.3v5h6.7V72.6h-6.7V97.3z M336.6,125.1
	c-1.8,1.2-4,1.7-6.3,1.7c-1.9,0-6.1-0.2-9.3-4.3c-3.1-4.1-3-9.1-3-10.6c0-4,0.7-7.7,3.3-10.8c3.2-3.8,7.3-4.1,9.1-4.1
	c5.9,0.1,10.3,4,11.6,9.7c0.5,2.1,0.5,4.3,0.5,5.1C342.6,119.3,339.5,123.3,336.6,125.1z"
            />
            <path
              d="M388.6,122.9c-0.1-0.8-0.1-1.8-0.1-2.6v-12.8c0-4.3-0.1-6.3-0.8-8.4c-1.9-5.3-7.2-6.9-8.8-7.3c-2.8-0.8-5-0.8-6.2-0.8
	c-9.5,0-13.2,5.3-14.4,9.1c-0.5,1.7-0.7,3.1-0.7,3.8l6.9,0.4c0-1.3,0-2.3,0.4-3.5c1.6-4.8,6.8-4.8,8.1-4.8c8,0,8.5,5.8,8.7,8.7
	c0.1,1.1,0.1,2.2,0.1,3.3c-1.9,0.3-4,0.3-6,0.4c-2.2,0.2-4.3,0.5-6.3,0.8c-1.2,0.2-5.9,0.8-9.6,3.6c-3.8,2.9-4.3,6.7-4.3,8.5
	c0,5.1,3.3,8.8,7.9,10.4c1,0.3,2.8,0.8,5.4,0.8c7.6,0,11.6-4.4,13.4-6.4c0.2,0.8,0.2,1.8,1.3,3.3c0.2,0.2,0.7,1,1.3,1.5
	c1.5,1,3.8,1,4.2,1c1.1,0,2.3-0.1,4.3-0.8v-5.6c-0.8,0.2-1.2,0.3-2,0.3C388.8,125.9,388.7,123.9,388.6,122.9z M378.1,124.5
	c-2.9,2.4-6.4,2.4-7.7,2.4c-3.7,0-5.6-1.4-6.5-2.8c-0.3-0.5-0.9-1.5-0.9-3.1c0-5.1,5.9-6.4,6.6-6.6c0.9-0.3,1.8-0.4,2.8-0.6
	c1.3-0.3,2.6-0.6,3.9-0.8c1.8-0.3,3.8-0.5,5.6-0.8c0,1,0.1,2.1,0.1,3.2C382,117,382,121.4,378.1,124.5z"
            />
            <path
              d="M408.7,79.7h-6.8v12.4h-6.3v5.5h6.3v25.5c-0.1,3.4-0.1,5,0.9,6.5c1,1.5,2.9,2.4,6.7,2.4c2.6,0,5.8-0.6,6.3-0.7v-5.5
	c-1.8,0.7-3.4,0.9-4.2,0.9c-1.1,0-1.9-0.3-2.4-1.2c-0.7-1.2-0.6-2.8-0.5-5.5V97.7h7.1v-5.5h-7.1V79.7z"
            />
            <rect x="423.7" y="92.2" width="6.4" height="39.3" />
            <rect x="423.7" y="72.6" width="6.4" height="7.7" />
            <path
              d="M464.6,92.4c-2.3-1-5.2-1.5-7.8-1.5c-6.2,0-11.3,1.9-15.1,6.8c-3.2,4.1-4.5,9.2-4.5,14.3c0,8.3,3.3,13,5.8,15.5
	c4.8,4.9,11.3,5.2,13.6,5.2c8.2,0,12.4-4,14.6-6.6c4.3-5.4,4.6-12.1,4.6-14.3C475.9,99.9,469.2,94.3,464.6,92.4z M468.5,111.8
	c-0.1,0.8-0.2,6.9-2.7,10.7c-3,4.5-7.5,4.8-9.3,4.9c-9.3,0-11.3-8.3-11.8-11.1c-0.1-0.6-0.3-2.1-0.3-4c0-2.3-0.1-16,12.5-16
	c1.3,0,4.3,0.1,7.2,2.5c0.9,0.8,1.8,1.9,2.4,3C468.6,105.6,468.5,110.7,468.5,111.8z"
            />
            <path
              d="M512.6,96.3c-3.5-4.9-9.4-5.4-11.2-5.4c-5.4,0-8.8,2.8-10.3,4.3c-0.4,0.4-1.3,1.3-2.1,2.8v-5.8h-6.7v39.3h6.7v-22.3
	c0-1.9,0-3.6,0.8-5.4c0.7-1.8,1.8-3.1,2.3-3.6c2.1-2,4.4-2.9,7.3-2.9c3.1,0,4.9,1,5.9,1.8c3.3,2.5,3,6.3,3,7.5v25h6.6V108
	C515,103.8,515.2,99.9,512.6,96.3z"
            />
            <polygon
              points="146.6,131.4 154.5,131.4 154.5,103.8 182.3,103.8 182.3,96.9 154.5,96.9 154.5,79.6 184.4,79.6 184.4,72.6
	146.6,72.6 "
            />
            <path
              d="M106.3,0H77.9h-7H42.5h-7H7H0v7v82v7v28.4v3.2v3.8h113.3v-3.8v-3.2V96v-7V7V0H106.3z M70.9,7v82H42.5V7H70.9z M7,7h28.4v82
	H7V7z M106.3,124.4H7V96h99.3V124.4z M106.3,89H77.9V7h28.4V89z"
            />
          </g>
        </svg>
      </a>
    </Container>
  )
}

export default LogoFF
