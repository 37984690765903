import create from 'zustand'

import { ControlScheme, Features, View } from '../types'

type InitialState = {
  controlScheme?: ControlScheme
  // Supported browser features
  features?: Features
  viewActive: View
  viewTransition?: View
}

const initialState: InitialState = {
  controlScheme: undefined,
  features: undefined,
  viewActive: 'exhibition',
  viewTransition: undefined,
}

export const [useStore, api] = create((set, get) => ({
  ...initialState,
  getControlScheme: () => get().controlScheme,
  setFeatures: (features: Features) => {
    let controlScheme: ControlScheme
    if (features.hasFinePointer && features.pointerLock) {
      controlScheme = 'pointerLock'
    }
    if (!features.hasFinePointer) {
      controlScheme = 'touch'
    }

    set({
      controlScheme,
      features,
    })
  },
  setViewActive: (viewActive: View) =>
    set({
      viewActive,
      viewTransition: null,
    }),
  setViewTransition: (viewTransition: View) => set({ viewTransition }),
}))
