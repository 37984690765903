import React from 'react'
import { ReactThreeFiber } from 'react-three-fiber/three-types'
import * as THREE from 'three'

type Props = ReactThreeFiber.Object3DNode<THREE.Mesh, typeof THREE.Mesh> & {
  // TODO: force position vec3
  width: number
}

const Bookshelf = (props: Props) => {
  const { width, ...meshProps } = props

  return (
    <>
      {/* Frame */}
      <mesh castShadow={true} receiveShadow={true} {...meshProps}>
        <boxBufferGeometry attach="geometry" args={[width, 0.07, 0.4]} />
        <meshStandardMaterial
          attach="material"
          color="#0030ff"
          // opacity={0.25}
          transparent={true}
        />
      </mesh>
    </>
  )
}

export default Bookshelf
