import { useEffect } from 'react'

function useKeyUp(targetKeys: string[], callback: Function, updates?: any[]) {
  // If released key is our target key then set to false
  const upHandler = (e: KeyboardEvent) => {
    if (targetKeys.includes(e.key)) {
      if (callback) {
        callback()
      }
    }
  }

  // Add event listeners
  useEffect(() => {
    window.addEventListener('keyup', upHandler)
    // Remove event listeners on cleanup
    return () => {
      window.removeEventListener('keyup', upHandler)
    }
  }, updates || [])
}

export default useKeyUp
