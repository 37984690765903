import React from 'react'

import Box from '../Box'
import TextOutline from '../TextOutline'

type Props = {}

const Fallback = (props: Props) => {
  return (
    <Box
      alignItems="center"
      color="white"
      display="flex"
      flexDirection="column"
      fontFamily="sans"
      justifyContent="center"
      pb={6}
      pt={4}
      px={4}
      position="absolute"
      size="100%"
      textAlign="center"
    >
      <TextOutline
        display="block"
        fontSize={[5, 5, 6]}
        fontWeight="bold"
        left="50%"
        top={4}
      >
        Must Should Could
      </TextOutline>
      <Box
        alignItems="center"
        display="flex"
        fontSize={[1, 1, 2]}
        height="100%"
        lineHeight="body"
        justifyContent="center"
        maxWidth="600px"
        px={3}
        textAlign="center"
      >
        This gallery experience is not supported for your current browser. We
        recommend browsing this on your desktop using the latest Chrome browser.
      </Box>
    </Box>
  )
}

export default Fallback
