import React from 'react'

import Pill from '../Pill'
import { useExhibitionStore } from '../../store/exhibition'

type Props = {}

const InstructionProgress = (props: Props) => {
  const instructions = useExhibitionStore(state => state.instructions)

  return (
    <>
      {instructions &&
        instructions.map((instruction: any, index: number) => (
          <Pill
            instruction={instruction.type}
            key={index}
            mx="auto"
            mb={[1, 1, 2]}
            variant={instruction.complete ? 'light' : 'dark'}
          />
        ))}
    </>
  )
}

export default InstructionProgress
