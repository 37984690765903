import { AnimatePresence } from 'framer-motion'
import React from 'react'

import intersectAction from '../../lib/intersectAction'
import { useStore } from '../../store'
import { useExhibitionStore } from '../../store/exhibition'
import ArtworkDescription from '../ArtworkDescription'
import Button from '../Button'
import BoxMotion from '../BoxMotion'
import TextOutline from '../TextOutline'

type Props = {}

const ActiveIntersect = () => {
  // Store (global)
  const controlScheme = useStore(state => state.controlScheme)
  // Store (exhibition)
  const activeScene = useExhibitionStore(state => state.activeScene)
  const activeVideo = useExhibitionStore(state => state.activeVideo)
  const activeVideoPlaying = useExhibitionStore(
    state => state.activeVideoPlaying
  )
  const intersectingObject = useExhibitionStore(
    state => state.intersectingObject
  )
  const sceneTransition = useExhibitionStore(state => state.sceneTransition)
  const sphereArtwork = useExhibitionStore(state => state.sphereArtwork)

  const artworkDetail = [
    'imageCylinder',
    'imageSphere',
    'videoSphere',
  ].includes(activeScene)
    ? sphereArtwork?.detail
    : intersectingObject?.detail

  const intersectingObjectAction = intersectingObject?.action

  const handleToggleVideoPlayback = () => {
    if (!activeVideo) {
      return
    }

    if (activeVideoPlaying) {
      activeVideo.pause()
    } else {
      activeVideo.play()
    }
  }

  return (
    <AnimatePresence>
      {/* Artwork */}
      {(artworkDetail || intersectingObjectAction === 'about') && (
        <BoxMotion
          // framer-motion
          animate={{ opacity: sceneTransition ? 0 : 1 }}
          exit={{ opacity: 0 }}
          initial={{ opacity: 0 }}
          // styled props
          bottom={[5, 5, 6]}
          key={`${intersectingObject?.detail?.artist}-${intersectingObject?.detail?.category}-${intersectingObject?.detail?.caption}`}
          position="fixed"
          textAlign="center"
          width="100%"
        >
          {artworkDetail && (
            <ArtworkDescription
              detail={artworkDetail}
              variant={
                ['imageCylinder', 'imageSphere', 'videoSphere'].includes(
                  activeScene
                )
                  ? 'light'
                  : 'dark'
              }
            />
          )}

          {intersectingObjectAction === 'about' && (
            <TextOutline
              display="block"
              fontSize={[3, 3, 4]}
              fontWeight="bold"
              textStrokeColor="black"
            >
              About the exhibition
            </TextOutline>
          )}

          {activeScene === 'mainHall' && controlScheme === 'touch' && (
            <Button mt={2} mx="auto" onClick={intersectAction} variant="dark">
              View
            </Button>
          )}

          {activeScene === 'videoSphere' &&
            activeVideo &&
            controlScheme === 'touch' && (
              <Button
                mt={[2, 2, 3]}
                mx="auto"
                onClick={handleToggleVideoPlayback}
                variant="light"
              >
                {activeVideoPlaying ? 'Pause' : 'Play'}
              </Button>
            )}
        </BoxMotion>
      )}
    </AnimatePresence>
  )
}

export default ActiveIntersect
