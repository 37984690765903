import React, { useEffect, useRef } from 'react'
import { useFrame } from 'react-three-fiber'
import { ReactThreeFiber } from 'react-three-fiber/three-types'
import * as THREE from 'three'

import { useExhibitionStore } from '../../store/exhibition'

type Props = ReactThreeFiber.Object3DNode<THREE.Mesh, typeof THREE.Mesh> & {
  index: number
  position: [number, number, number]
  texture?: THREE.VideoTexture
}

const GEOMETRY_WIDTH = 3.2

const VideoPlane = (props: Props) => {
  const { index, position, texture, ...meshProps } = props

  const mesh = useRef<THREE.Mesh>()
  // Store (exhibition)
  const sphereArtwork = useExhibitionStore(state => state.sphereArtwork)

  useEffect(() => {
    if (mesh.current) {
      // Look at camera
      mesh.current.lookAt(0, 0, 0)
    }
  }, [])

  useFrame(state => {
    if (mesh.current) {
      const time = state.clock.getElapsedTime()
      const offset = index * 5
      // Float in place
      mesh.current.position.y =
        position[1] + 0.2 * (0.25 * Math.sin(time + offset) + 1)
    }
  })

  return (
    <mesh position={position} ref={mesh} {...meshProps}>
      <planeBufferGeometry
        args={[
          GEOMETRY_WIDTH,
          GEOMETRY_WIDTH / sphereArtwork.video.aspectRatio,
        ]}
        attach="geometry"
      />
      <meshBasicMaterial
        attach="material"
        // color={highlighted ? 0x333333 : 0x000000}
        map={texture}
      />
    </mesh>
  )
}

export default VideoPlane
