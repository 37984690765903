import React, { useEffect } from 'react'
import styled from 'styled-components'

import { useStore } from '../../store'
import { useContentStore } from '../../store/content'
import { useExhibitionStore } from '../../store/exhibition'
import SceneBook from '../../three-fibers/SceneBook'
import { BoxProps, ArtworkBook } from '../../types'
import Box from '../Box'
import Button from '../Button'
import TextOutline from '../TextOutline'

type Props = BoxProps & {}

const Container = styled(Box)`
  background: rgb(16, 16, 16);

  canvas {
    outline: none;
    width: 100% !important;
    height: 100% !important;
  }
`

const Books = (props: Props) => {
  const { ...boxProps } = props

  // Store (global)
  const controlScheme = useStore(state => state.controlScheme)
  const setViewTransition = useStore(state => state.setViewTransition)
  // Store (exhibition)
  const completeInstruction = useExhibitionStore(
    state => state.completeInstruction
  )

  const handleClick = () => {
    if (controlScheme === 'pointerLock') {
      document.body.requestPointerLock()
    }

    setViewTransition('exhibition')
  }

  useEffect(() => {
    if (controlScheme === 'pointerLock') {
      document.exitPointerLock()
    }

    completeInstruction('wander')

    // Re-enable document overflow scroll
    document.documentElement.style.overflow = 'auto'

    return () => {
      // Hide document overflow scroll (to prevent pull-to-refresh on mobile chrome)
      document.documentElement.style.overflow = 'hidden'
    }
  }, [])

  const books = useContentStore(state => state.books)

  return (
    <Container
      color="white"
      height="auto"
      justifyContent="center"
      minHeight="100vh"
      overflowY="auto"
      pb={3}
      position="relative"
      mx="auto"
      width="100%"
      {...boxProps}
    >
      {/* Title */}
      <TextOutline
        display="block"
        fontSize={[5, 5, 6]}
        mt={[7, 7, 4]}
        textAlign="center"
      >
        Project Archive
      </TextOutline>

      {/* Books */}
      <Box maxWidth="1400px" mx="auto" width="100%">
        {books &&
          books.map((book: ArtworkBook, index: number) => {
            const { button, title, year } = book
            return (
              <Box height="100vh" minHeight="600px" key={index}>
                <Box
                  height="70vh"
                  minHeight="420px"
                  // Disable orbit controls on touch, otherwise it'll interfere with scrolling
                  pointerEvents={controlScheme === 'touch' ? 'none' : 'auto'}
                >
                  <SceneBook {...book} />
                </Box>

                {/* Details */}
                <Box mt={1} textAlign="center">
                  {/* Year */}
                  <Box fontSize={[1, 1, 2]}>{year}</Box>

                  {/* Title */}
                  <TextOutline
                    display="block"
                    fontSize={[4, 4, 5]}
                    mt={[1, 1, 2]}
                    px={3}
                  >
                    {title}
                  </TextOutline>

                  {/* Button */}
                  <a
                    href={button.url}
                    rel="noreferrer noopener"
                    target="_blank"
                  >
                    <Button mt={[1, 1, 2]}>{button.label}</Button>
                  </a>
                </Box>
              </Box>
            )
          })}
      </Box>

      {/* Return to site button */}
      <Button
        bg="rgb(16,16,16)"
        onClick={handleClick}
        position="fixed"
        right={['50%', '50%', 4]}
        top={[3, 3, 4]}
        transform={['translate(50%, 0)', 'translate(50%, 0)', 'none']}
      >
        Return to site
      </Button>
    </Container>
  )
}

export default Books
