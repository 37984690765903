import { useEffect, useCallback } from 'react'

import intersectAction from '../../lib/intersectAction'
import { useStore } from '../../store'
import { usePlayerStore } from '../../store/player'

type Props = {}

// FIXME: This is a quick workaround to get around
// https://github.com/react-spring/react-three-fiber/issues/317
// `onClick` Object3D events in R3F use incorrect raycaster direction when the Pointer is locked.
// Instead, we globally listen to all click events and check current intersects to determine click event.
const ControlsClickEvents = () => {
  // Store (global)
  const controlScheme = useStore(state => state.controlScheme)
  // Store (player)
  const pointerLocked = usePlayerStore(state => state.pointerLocked)

  const handleClick = useCallback(() => {
    const active = controlScheme === 'pointerLock' && pointerLocked

    if (active) {
      intersectAction()
    }
  }, [pointerLocked])

  useEffect(() => {
    document.body.addEventListener('click', handleClick)
    return () => {
      document.body.removeEventListener('click', handleClick)
    }
  })

  return null
}

export default ControlsClickEvents
