import React, { Suspense } from 'react'
import { Canvas, CanvasContext, useFrame } from 'react-three-fiber'
import * as THREE from 'three'

import { useStore } from '../../store'
import { useExhibitionStore } from '../../store/exhibition'
import CrosshairRay from '../CrosshairRay'
// import FPSStats from '../FPSStats'
import Player from '../Player'
import SceneExhibitionImageCylinder from '../SceneExhibitionImageCylinder'
import SceneExhibitionImageSphere from '../SceneExhibitionImageSphere'
import SceneExhibitionMainHall from '../SceneExhibitionMainHall'
import SceneExhibitionVideoSphere from '../SceneExhibitionVideoSphere'

const ConditionalRender = () => {
  const viewActive = useStore(state => state.viewActive)

  // Take over rendering duties, and only render if we're currently in the
  // exhibition view
  useFrame(({ gl, scene, camera }) => {
    if (viewActive === 'exhibition') {
      gl.render(scene, camera)
    }
  }, 1)

  return null
}

const SceneExhibition = () => {
  // Store (global)
  const viewActive = useStore(state => state.viewActive)
  const viewTransition = useStore(state => state.viewTransition)
  // Store (exhibition)
  const activeScene = useExhibitionStore(state => state.activeScene)
  const backgroundColor = useExhibitionStore(state => state.backgroundColor)
  const introComplete = useExhibitionStore(state => state.introComplete)

  const active =
    introComplete &&
    (viewActive === 'exhibition' || viewTransition === 'exhibition')

  const handleCreated = (context: CanvasContext) => {
    const { gl } = context
    // http://filmicworlds.com/blog/filmic-tonemapping-operators/
    gl.toneMapping = THREE.Uncharted2ToneMapping
    // gl.setClearColor(clearColor)
  }

  return (
    <Canvas
      colorManagement={true}
      invalidateFrameloop={!active}
      onCreated={handleCreated}
      pixelRatio={1}
      raycaster={{
        // far: 3,
        filter: items => items.slice(0, 1),
      }}
      shadowMap={true}
      style={{
        background: backgroundColor,
      }}
    >
      <ConditionalRender />
      {/* <FPSStats /> */}
      <Player />
      <CrosshairRay />

      <Suspense fallback={null}>
        {/* Scenes */}
        {activeScene === 'mainHall' && <SceneExhibitionMainHall />}
        {activeScene === 'imageCylinder' && <SceneExhibitionImageCylinder />}
        {activeScene === 'imageSphere' && <SceneExhibitionImageSphere />}
        {activeScene === 'videoSphere' && <SceneExhibitionVideoSphere />}
      </Suspense>
    </Canvas>
  )
}

export default SceneExhibition
