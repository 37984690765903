import React, { useEffect, useRef } from 'react'
import { useLoader } from 'react-three-fiber'
import { ReactThreeFiber } from 'react-three-fiber/three-types'
import * as THREE from 'three'

import { useExhibitionStore } from '../../store/exhibition'
import { ArtworkCylinder } from '../../types'

type Props = ReactThreeFiber.Object3DNode<THREE.Mesh, typeof THREE.Mesh> &
  ArtworkCylinder

const Cylinder = (props: Props) => {
  const { detail, image, mesh: meshProps } = props

  const mesh = useRef<THREE.Mesh>()

  // Store (exhibition)
  const addToCollisionList = useExhibitionStore(
    state => state.addToCollisionList
  )

  // Load texture
  const texture = useLoader(THREE.TextureLoader, image.src)
  texture.anisotropy = 4
  texture.encoding = THREE.sRGBEncoding

  useEffect(() => {
    // Add to collision table
    addToCollisionList(mesh?.current)
  }, [])

  return (
    <mesh
      ref={mesh}
      userData={{
        action: 'artworkFrame',
        detail,
        image,
      }}
      {...meshProps}
    >
      <cylinderBufferGeometry
        args={[
          meshProps.radius,
          meshProps.radius,
          meshProps.height,
          48,
          1,
          true,
          meshProps.thetaStart,
          meshProps.thetaLength,
        ]}
        attach="geometry"
      />
      <meshBasicMaterial
        attach="material"
        // color="blue"
        map={texture}
        // side={THREE.DoubleSide}
        transparent={image.transparent}
      />
    </mesh>
  )
}

export default Cylinder
