import React, { useRef } from 'react'
import { ReactThreeFiber } from 'react-three-fiber/three-types'
import * as THREE from 'three'
import shallow from 'zustand/shallow'

import { useExhibitionStore } from '../../store/exhibition'

type Props = ReactThreeFiber.Object3DNode<
  THREE.PointLight,
  typeof THREE.PointLight
> & {}

const PointLight = (props: Props) => {
  const { ...lightProps } = props

  // Store (exhibition)
  const { lightHelpers, pointLightShadowBias } = useExhibitionStore(
    state => ({
      lightHelpers: state.lightHelpers,
      pointLightShadowBias: state.pointLightShadowBias,
    }),
    shallow
  )

  const light = useRef<THREE.PointLight>()

  return (
    <>
      <pointLight
        args={[0xffffff, 1, 0, 2]}
        distance={30}
        castShadow={true}
        ref={light}
        shadow-bias={pointLightShadowBias}
        shadow-radius={4}
        {...lightProps}
      />
      {light.current && lightHelpers && (
        <pointLightHelper args={[light.current, 1]} />
      )}
    </>
  )
}

export default PointLight
