import React, { useEffect, useRef } from 'react'
import { useFrame, useLoader } from 'react-three-fiber'
import { ReactThreeFiber } from 'react-three-fiber/three-types'
import * as THREE from 'three'

import { ArtworkMedia } from '../../types'
import offsetTexture from '../../utils/offsetTexture'

type Props = ReactThreeFiber.Object3DNode<THREE.Mesh, typeof THREE.Mesh> & {
  aspectRatio: number
  image: ArtworkMedia
  index: number
  position: [number, number, number]
}

const GEOMETRY_WIDTH = 3.2

const ImagePlane = (props: Props) => {
  const { aspectRatio, image, index, position, ...meshProps } = props

  const mesh = useRef<THREE.Mesh>()

  // Load texture
  const texture = useLoader(THREE.TextureLoader, image.src)
  texture.anisotropy = 4
  texture.encoding = THREE.sRGBEncoding

  // Offset texture for non power-of-two aspect destination
  offsetTexture(texture, image.aspectRatio, aspectRatio)

  useEffect(() => {
    if (mesh.current) {
      // Look at camera
      mesh.current.lookAt(0, 0, 0)
    }
  }, [])

  useFrame(state => {
    if (mesh.current) {
      const time = state.clock.getElapsedTime()
      const offset = index * 5
      // Float in place
      mesh.current.position.y =
        position[1] + 0.2 * (0.25 * Math.sin(time + offset) + 1)
    }
  })

  return (
    <mesh position={position} ref={mesh} {...meshProps}>
      <planeBufferGeometry
        args={[GEOMETRY_WIDTH, GEOMETRY_WIDTH / aspectRatio]}
        attach="geometry"
      />
      <meshBasicMaterial attach="material" map={texture} />
    </mesh>
  )
}

export default ImagePlane
