import React from 'react'
import styled, { css } from 'styled-components'

import Box from '../Box'
import { useStore } from '../../store'
import { useExhibitionStore } from '../../store/exhibition'
import { IntersectionAction } from '../../types'
import IconClose from '../IconClose'

type Props = {}

const CloseContainer = styled(Box)`
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  position: fixed;
  width: 30px;
  height: 30px;

  svg {
    width: 100%;
    height: auto;
  }
`

const CircleContainer = styled(Box)<{
  intersectionAction?: IntersectionAction
}>`
  z-index: ${props => props.theme.zIndices.crosshair};
  transition: all 300ms;
  transform: translate(-50%, -50%);
  position: fixed;
  top: 50%;
  left: 50%;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: transparent;

  /* Default state */
  border: 4px solid rgb(255, 255, 255, 0.25);

  ${props =>
    (props.intersectionAction === 'about' ||
      props.intersectionAction === 'artworkFrame' ||
      props.intersectionAction === 'enterMainHall' ||
      props.intersectionAction === 'enterImageCylinder' ||
      props.intersectionAction === 'enterImageSphere' ||
      props.intersectionAction === 'enterVideoSphere') &&
    css`
      border: 4px solid rgb(255, 255, 255, 1);
    `}
`

const Crosshair = (props: Props) => {
  // Store (global)
  const controlScheme = useStore(state => state.controlScheme)
  // Store (exhibition)
  const activeScene = useExhibitionStore(state => state.activeScene)
  const intersectingObject = useExhibitionStore(
    state => state.intersectingObject
  )
  const introComplete = useExhibitionStore(state => state.introComplete)

  const visible = introComplete

  if (!visible) {
    return null
  }

  const intersectingObjectAction = intersectingObject?.action

  const closeVisible =
    controlScheme === 'pointerLock' &&
    ['imageCylinder', 'imageSphere', 'videoSphere'].includes(activeScene)

  if (closeVisible) {
    return (
      <CloseContainer>
        <IconClose />
      </CloseContainer>
    )
  }

  return <CircleContainer intersectionAction={intersectingObjectAction} />
}

export default Crosshair
