import React from 'react'
import DatGui, {
  DatColor,
  DatFolder,
  DatNumber,
  // DatString,
} from 'react-dat-gui'
import styled from 'styled-components'
import 'react-dat-gui/dist/index.css'

import { useExhibitionStore } from '../../store/exhibition'
import { usePlayerStore } from '../../store/player'

type Props = {}

const StyledDatGui = styled(DatGui)`
  z-index: ${props => props.theme.zIndices.debug};
  position: fixed;
  top: 0;
  right: 0;

  /* DAT.GUI css overrides */
  font-family: ${props => props.theme.fonts.system};
  letter-spacing: 0;

  .title {
    font-weight: normal !important;
  }
`

// FIXME: investigate unnecessary re-renders
const DebugPanel = (props: Props) => {
  // Store (exhibition)
  const debugPanel = useExhibitionStore(state => state.debugPanel)
  const exhibitionStore = useExhibitionStore()
  const playerStore = usePlayerStore()

  const data = {
    exhibition: exhibitionStore,
    player: playerStore,
  }

  const handleUpdate = (newData: any) => {
    // Update player store
    playerStore.setPlayerData({ ...newData.player })

    // Update exhibition store
    exhibitionStore.setData({ ...newData.exhibition })
  }

  if (!debugPanel) {
    return null
  }

  return (
    <StyledDatGui data={data} onUpdate={handleUpdate} style={{}}>
      {/* Player */}
      <DatFolder closed={false} title="Player">
        <DatNumber
          label="Height (m)"
          max={2.5}
          min={0.6}
          path="player.height"
          step={0.01}
        />
        <DatNumber label="FOV" max={160} min={30} path="player.fov" step={1} />
        <DatNumber
          label="Movement speed"
          max={60}
          min={20}
          path="player.movementSpeed"
          step={1}
        />
      </DatFolder>

      {/* Exhibition */}
      <DatFolder closed={false} title="Exhibition">
        <DatColor label="Background color" path="exhibition.backgroundColor" />
        <DatColor label="Sphere color" path="exhibition.sphereColor" />
        <DatFolder closed={true} title="Point light">
          <DatNumber
            max={0}
            min={-0.001}
            label="Shadow bias "
            path="exhibition.pointLightShadowBias"
            step={0.0001}
          />
        </DatFolder>
      </DatFolder>

      {/* Cylinder test */}
      {/*
      <DatFolder closed={false} title="Cylinder">
        <DatNumber
          label="Height"
          max={5}
          min={0.1}
          path="exhibition.cylinderHeight"
          step={0.001}
        />
        <DatNumber
          label="Position X"
          max={20}
          min={-20}
          path="exhibition.cylinderPositionX"
          step={0.001}
        />
        <DatNumber
          label="Position Y"
          max={6}
          min={0}
          path="exhibition.cylinderPositionY"
          step={0.001}
        />
        <DatNumber
          label="Position Z"
          max={25}
          min={-25}
          path="exhibition.cylinderPositionZ"
          step={0.001}
        />
        <DatNumber
          label="Radius"
          max={11}
          min={0}
          path="exhibition.cylinderRadius"
          step={0.001}
        />
        <DatNumber
          label="Theta start"
          max={Math.PI * 2}
          min={0}
          path="exhibition.cylinderThetaStart"
          step={0.0001}
        />
        <DatNumber
          label="Theta length"
          max={Math.PI * 2}
          min={0}
          path="exhibition.cylinderThetaLength"
          step={0.0001}
        />
      </DatFolder>
      */}
    </StyledDatGui>
  )
}

export default DebugPanel
