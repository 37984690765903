import useKeyDown from '../../hooks/useKeyDown'
import useKeyUp from '../../hooks/useKeyUp'
// import { useExhibitionStore } from '../../store/exhibition'
import { usePlayerStore } from '../../store/player'

const KeyboardShortcuts = () => {
  // Store (exhibition)
  // const toggleLightHelpers = useExhibitionStore(state => state.toggleLightHelpers)
  // const toggleDebugPanel = useExhibitionStore(state => state.toggleDebugPanel)
  // Store (player)
  const setMovement = usePlayerStore(state => state.setMovement)
  const setRunning = usePlayerStore(state => state.setRunning)
  // const toggleFreeMovement = usePlayerStore(state => state.toggleFreeMovement)

  // Interface
  // useKeyUp(['c'], () => toggleFreeMovement())
  // useKeyUp(['i'], () => toggleDebugPanel())
  // useKeyUp(['l'], () => toggleLightHelpers())

  // Movement
  useKeyDown(['Shift'], () => setRunning(true))
  // prettier-ignore
  useKeyDown(['ArrowUp', 'Up', ' ', 'w', 'W'], () => setMovement('forward', true))
  useKeyDown(['ArrowLeft', 'Left', 'a', 'A'], () => setMovement('left', true))
  // prettier-ignore
  useKeyDown(['ArrowDown', 'Down', 's', 'S'], () => setMovement('backward', true))
  // prettier-ignore
  useKeyDown(['ArrowRight', 'Right', 'd', 'D'], () => setMovement('right', true))

  useKeyUp(['Shift'], () => setRunning(false))
  // prettier-ignore
  useKeyUp(['ArrowUp', 'Up', ' ', 'w', 'W'], () => setMovement('forward', false))
  useKeyUp(['ArrowLeft', 'Left', 'a', 'A'], () => setMovement('left', false))
  // prettier-ignore
  useKeyUp(['ArrowDown', 'Down', 's', 'S'], () => setMovement('backward', false))
  useKeyUp(['ArrowRight', 'Right', 'd', 'D'], () => setMovement('right', false))

  return null
}

export default KeyboardShortcuts
