import {
  background,
  border,
  color,
  flexbox,
  grid,
  layout,
  position,
  space,
  system,
  typography,
} from 'styled-system'
import styled from 'styled-components'

import { BoxProps } from '../../types'

const Box = styled.div<BoxProps>`
  ${background};
  ${border};
  ${color};
  ${flexbox};
  ${grid};
  ${layout};
  ${position};
  ${space};
  ${typography};

  ${system({
    boxShadow: true,
    boxSizing: true,
    breakBefore: true,
    columnCount: true,
    cursor: true,
    filter: true,
    float: true,
    fontStretch: true,
    objectFit: true,
    objectPosition: true,
    overflowY: true,
    pointerEvents: true,
    textDecoration: true,
    textOverflow: true,
    textTransform: true,
    transform: true,
    transition: true,
    userDrag: true,
    userSelect: true,
    whiteSpace: true,
  })}
`

export default Box
