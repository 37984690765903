import { useEffect, useRef } from 'react'
import { fromEvent, merge, timer } from 'rxjs'
import { switchMap, takeUntil } from 'rxjs/operators'

import { useStore } from '../../store'
import { playerApi, usePlayerStore } from '../../store/player'
import { useExhibitionStore } from '../../store/exhibition'

type Props = {}

const ControlsTouch = () => {
  // Store (global)
  const viewActive = useStore(state => state.viewActive)
  // Store (player)
  const setMovement = usePlayerStore(state => state.setMovement)
  // Store (exhibition)
  const aboutVisible = useExhibitionStore(state => state.aboutVisible)
  const activeArtwork = useExhibitionStore(state => state.activeArtwork)
  const intersectingObject = useExhibitionStore(
    state => state.intersectingObject
  )
  const started = useExhibitionStore(state => state.started)

  const previousTouch = useRef<{ clientX: number; clientY: number }>({
    clientX: 0,
    clientY: 0,
  })

  const active =
    viewActive === 'exhibition' && started && !aboutVisible && !activeArtwork

  // Also used by `touchcancel`
  const handleTouchEnd = () => {
    if (!active) {
      return
    }

    setMovement('forward', false)

    playerApi.setState({
      lookDelta: {
        x: 0,
        y: 0,
      },
    })
  }

  const handleTouchMove = (e: TouchEvent) => {
    if (!active) {
      return
    }

    const currentTouch = e.touches[0]
    const movementX = currentTouch.clientX - previousTouch.current.clientX
    const movementY = currentTouch.clientY - previousTouch.current.clientY

    // invert
    playerApi.setState({
      lookDelta: {
        x: -movementX,
        y: -movementY,
      },
    })

    previousTouch.current = {
      clientX: currentTouch.clientX,
      clientY: currentTouch.clientY,
    }
  }

  const handleTouchStart = (e: TouchEvent) => {
    if (!active) {
      return
    }

    previousTouch.current = {
      clientX: e.touches[0].clientX,
      clientY: e.touches[0].clientY,
    }
  }

  useEffect(() => {
    const touchEnd$ = fromEvent(document, 'touchend')
    const touchMove$ = fromEvent(document, 'touchmove')
    const touchStart$ = fromEvent(document, 'touchstart')

    const touchMoveAndEnd$ = merge(touchMove$, touchEnd$)

    const subscriberTouchStart = touchStart$
      .pipe(switchMap(() => timer(200).pipe(takeUntil(touchMoveAndEnd$))))
      .subscribe(() => {
        // TODO: find more more idiomatic rxjs way to do this
        // TODO: check for intersecting object

        if (!active) {
          return
        }

        setMovement('forward', true)
      })

    document.addEventListener('touchcancel', handleTouchEnd)
    document.addEventListener('touchend', handleTouchEnd)
    document.addEventListener('touchmove', handleTouchMove)
    document.addEventListener('touchstart', handleTouchStart)

    return () => {
      document.removeEventListener('touchcancel', handleTouchEnd)
      document.removeEventListener('touchend', handleTouchEnd)
      document.removeEventListener('touchmove', handleTouchMove)
      document.removeEventListener('touchstart', handleTouchStart)

      subscriberTouchStart.unsubscribe()
    }
  }, [active, intersectingObject])

  return null
}

export default ControlsTouch
