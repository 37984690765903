import { Features } from '../types'

// Pointer Lock API
export const hasPointerLock = () =>
  'pointerLockElement' in document ||
  'mozPointerLockElement' in document ||
  'webkitPointerLockElement' in document

// WebGL 1
export const hasWebGL = () => {
  try {
    var canvas = document.createElement('canvas')
    return !!(
      window.WebGLRenderingContext &&
      (canvas.getContext('webgl') || canvas.getContext('experimental-webgl'))
    )
  } catch (e) {
    return false
  }
}

// Return a record containing results of all feature detects
export const featureDetects = (): Features => ({
  hasFinePointer: matchMedia('(pointer:fine)').matches,
  // TODO: re-enable
  pointerLock: hasPointerLock(),
  webgl: hasWebGL(),
})
