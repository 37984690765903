import React, { useEffect, useMemo, useRef } from 'react'
import { useLoader } from 'react-three-fiber'
import { ReactThreeFiber } from 'react-three-fiber/three-types'
import * as THREE from 'three'

import { ArtworkBook } from '../../types'

type Props = ReactThreeFiber.Object3DNode<THREE.Mesh, typeof THREE.Mesh> &
  ArtworkBook & {}

const Book = (props: Props) => {
  const { mesh: meshProps, textures } = props

  const mesh = useRef<THREE.Mesh>()

  // const offset = useMemo(() => Math.random(), [])
  // const initialRotationY = useMemo(() => Math.PI * Math.random(), [])
  const initialRotationY = 0

  // Load texture (front)
  const texFront = useLoader(THREE.TextureLoader, textures.front.src)
  texFront.anisotropy = 8
  texFront.encoding = THREE.sRGBEncoding

  // Load texture (back)
  const texBack = useLoader(THREE.TextureLoader, textures.back.src)
  texBack.anisotropy = 8
  texBack.encoding = THREE.sRGBEncoding

  // Load texture (spine)
  const texSpine = useLoader(THREE.TextureLoader, textures.spine.src)
  texSpine.anisotropy = 8
  texSpine.encoding = THREE.sRGBEncoding

  useEffect(() => {
    // Create materials array
    const materialBack = new THREE.MeshBasicMaterial({ map: texBack })
    const materialCanvas = new THREE.MeshBasicMaterial({ color: 0xaaaaaa })
    const materialFront = new THREE.MeshBasicMaterial({ map: texFront })
    const materialSpine = new THREE.MeshBasicMaterial({ map: texSpine })

    const boxMaterials = [
      materialCanvas, // RHS
      materialSpine, // LHS
      materialCanvas, // Top
      materialCanvas, // Bottom
      materialFront, // Front
      materialBack, // Back
    ]

    if (mesh.current) {
      mesh.current.material = boxMaterials
    }
  }, [])

  const width = meshProps.height * meshProps.aspectRatio

  return (
    <mesh
      ref={mesh}
      rotation={[0, initialRotationY, -Math.PI * 0.05]}
      {...meshProps}
    >
      <boxBufferGeometry
        attach="geometry"
        args={[width, meshProps.height, meshProps.depth]}
      />
    </mesh>
  )
}

export default Book
