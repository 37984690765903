import React from 'react'
import styled from 'styled-components'
import Box from '../Box'

type Props = {
  src: string
}

const Container = styled(Box)<{
  src: string
}>`
  width: 100%;
  height: 100%;

  background-image: url(${props => props.src});
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
`

const ImageBackground = (props: Props) => {
  const { src } = props

  return <Container src={src} />
}

export default ImageBackground
