import create from 'zustand'

import { MovementDirection } from '../types'

type InitialState = {
  // Pointer lock mouse / touch movement
  freeLook: boolean
  // 'No clip' mode
  freeMovement?: boolean
  fov?: number
  bookmarkedFocusPoint?: THREE.Vector3
  bookmarkedPosition?: THREE.Vector3
  // Height (in meters)
  height: number
  // Prevent movement if enabled
  heldInPlace?: boolean
  // Relative look movement
  lookDelta: {
    x: number
    y: number
  }
  // Movement
  movement?: {
    backward?: boolean
    forward?: boolean
    left?: boolean
    right?: boolean
  }
  // Base walk speed
  movementSpeed: number
  // Pointer lock state
  pointerLocked?: boolean
  running: boolean
}

const initialState: InitialState = {
  freeLook: true,
  freeMovement: false,
  fov: 80,
  bookmarkedFocusPoint: undefined,
  bookmarkedPosition: undefined,
  height: 1.66,
  heldInPlace: false,
  lookDelta: {
    x: 0,
    y: 0,
  },
  movement: {
    backward: false,
    forward: false,
    left: false,
    right: false,
  },
  movementSpeed: 30,
  pointerLocked: false,
  running: false,
}

export const [usePlayerStore, playerApi] = create((set, get) => ({
  ...initialState,
  setFov: (fov: number) => set({ fov }),
  setFreeLook: (freeLook: boolean) => set({ freeLook }),
  setBookmarkedFocusPoint: (point: THREE.Vector3) =>
    set({
      bookmarkedFocusPoint: point,
    }),
  setBookmarkedPosition: (position: THREE.Vector3) =>
    set({
      bookmarkedPosition: position,
    }),
  setHeldInPlace: (heldInPlace: boolean) => set({ heldInPlace }),
  setMovement: (direction: MovementDirection, enabled: boolean) => {
    set(state => {
      return {
        movement: {
          ...state.movement,
          [direction]: enabled,
        },
      }
    })
  },
  setPlayerData: (data: any) => set({ ...data }),
  setPointerLocked: (pointerLocked: boolean) => {
    // Stop movement if pointer is unlocked
    if (!pointerLocked) {
      set({
        movement: {
          backward: false,
          forward: false,
          left: false,
          right: false,
        },
      })
    }

    set({
      pointerLocked,
    })
  },
  setRunning: (running: boolean) => set({ running }),
  toggleFreeMovement: () =>
    set({
      freeMovement: !get().freeMovement,
    }),
}))
