import { AnimatePresence } from 'framer-motion'
import React from 'react'

import { useExhibitionStore } from '../../store/exhibition'
import BoxMotion from '../BoxMotion'
import Pill from '../Pill'

type Props = {}

const motionProps = {
  animate: {
    opacity: 1,
    y: '0%',
  },
  exit: {
    opacity: 0,
    y: '-50%',
  },
  initial: {
    opacity: 0,
    y: '50%',
  },
  transition: {
    damping: 300,
    mass: 1,
    stiffness: 100,
    type: 'spring',
  },
}

const Instructions = (props: Props) => {
  // Store (exhibition)
  const activeArtwork = useExhibitionStore(state => state.activeArtwork)
  const activeScene = useExhibitionStore(state => state.activeScene)
  const instruction = useExhibitionStore(state => state.instruction)
  const introComplete = useExhibitionStore(state => state.introComplete)

  const visible = introComplete && activeScene === 'mainHall' && !activeArtwork

  // TODO: iterate through store instructions dynamically
  return (
    <AnimatePresence>
      {visible && !instruction && (
        <BoxMotion
          left={[3, 3, 4]}
          position="fixed"
          top={[3, 3, 4]}
          zIndex="instructions"
          {...motionProps}
        >
          <Pill />
        </BoxMotion>
      )}

      {visible && instruction === 'move' && (
        <BoxMotion
          left={[3, 3, 4]}
          key="move"
          position="fixed"
          right={[3, 3, 4]}
          top={[3, 3, 4]}
          zIndex="instructions"
          {...motionProps}
        >
          <Pill instruction="move" />
        </BoxMotion>
      )}
      {visible && instruction === 'explore' && (
        <BoxMotion
          left={[3, 3, 4]}
          key="explore"
          position="fixed"
          right={[3, 3, 4]}
          top={[3, 3, 4]}
          zIndex="instructions"
          {...motionProps}
        >
          <Pill instruction="explore" />
        </BoxMotion>
      )}
      {visible && instruction === 'wander' && (
        <BoxMotion
          left={[3, 3, 4]}
          key="wander"
          position="fixed"
          right={[3, 3, 4]}
          top={[3, 3, 4]}
          zIndex="instructions"
          {...motionProps}
        >
          <Pill instruction="wander" />
        </BoxMotion>
      )}
    </AnimatePresence>
  )
}

export default Instructions
