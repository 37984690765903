import React from 'react'
import shallow from 'zustand/shallow'

import { useStore } from '../../store'
import Box from '../Box'
import BoxMotion from '../BoxMotion'
import Books from '../Books'
import Exhibition from '../Exhibition'

type Props = {}

const Main = () => {
  const { setViewActive, viewActive, viewTransition } = useStore(
    state => ({
      setViewActive: state.setViewActive,
      viewActive: state.viewActive,
      viewTransition: state.viewTransition,
    }),
    shallow
  )

  return (
    <BoxMotion
      // framer-motion
      animate={{
        opacity: !viewTransition ? 1 : 0,
      }}
      initial={{
        opacity: 0,
      }}
      onAnimationComplete={() => {
        if (viewTransition) {
          setViewActive(viewTransition)
        }
      }}
      transition={{
        ease: 'easeInOut',
        duration: 1,
      }}
      // styled-props
      fontFamily="sans"
    >
      {/* Main 3D scene - main hall + sphere scenes */}
      <Box opacity={viewActive === 'exhibition' ? 1 : 0}>
        <Exhibition />
      </Box>

      {/* Secondary book scene - separate spinnable books */}
      {viewActive === 'books' && <Books />}
    </BoxMotion>
  )
}

export default Main
