import React, { useEffect } from 'react'
import { useThree } from 'react-three-fiber'
import shallow from 'zustand/shallow'

import { usePlayerStore } from '../../store/player'
import VideoSphere from '../VideoSphere'

type Props = {}

const SceneExhibitionVideoSphere = (props: Props) => {
  const { camera } = useThree()

  const { playerHeight, setHeldInPlace } = usePlayerStore(
    state => ({
      playerHeight: state.height,
      setHeldInPlace: state.setHeldInPlace,
    }),
    shallow
  )

  useEffect(() => {
    // Reset camera position + orientation
    camera.position.x = 0
    camera.position.y = playerHeight
    camera.position.z = 0

    camera.lookAt(0, playerHeight, -1)

    // Lock movement
    setHeldInPlace(true)
  }, [])

  return (
    <>
      {/* Video sphere */}
      <VideoSphere radius={6} />
    </>
  )
}

export default SceneExhibitionVideoSphere
