import React, { ReactNode } from 'react'
import styled, { css } from 'styled-components'

import { BoxProps } from '../../types'
import Box from '../Box'

type Props = BoxProps & {
  children?: ReactNode
  disabled?: boolean
  onClick?: () => void
  outline?: boolean
  variant?: 'dark' | 'light'
}

const Container = styled(Box)<{ disabled?: boolean }>`
  transition: all 300ms;

  ${props =>
    !props.disabled &&
    css`
      &:hover {
        @media (hover: hover) and (pointer: fine) {
          opacity: 0.5;
        }
      }
    `};
`

const Button = (props: Props) => {
  const {
    children,
    disabled = false,
    onClick,
    outline,
    variant = 'light',
    ...boxProps
  } = props

  return (
    <Container
      bg="transparent"
      borderColor={variant === 'light' ? 'white' : 'black'}
      borderStyle="solid"
      borderWidth="1px"
      borderRadius="pill"
      color={variant === 'light' ? 'white' : 'black'}
      cursor={disabled ? 'default' : 'pointer'}
      disabled={disabled}
      display="inline-block"
      fontSize={[0, 1, 2]}
      letterSpacing="tight"
      opacity={disabled ? 0.5 : 1}
      onClick={!disabled ? onClick : undefined}
      px={['9px', '12px', '15px']}
      py={['2px', '2px', '3px']}
      {...boxProps}
    >
      {children}
    </Container>
  )
}

export default Button
