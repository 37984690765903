import React from 'react'
import { motion } from 'framer-motion'
import shallow from 'zustand/shallow'

import { useExhibitionStore } from '../../store/exhibition'
import BoxMotion from '../BoxMotion'

type Props = {}

const BookCover = (props: Props) => {
  const { introComplete, setIntroComplete, started } = useExhibitionStore(
    state => ({
      introComplete: state.introComplete,
      setIntroComplete: state.setIntroComplete,
      started: state.started,
    }),
    shallow
  )

  // Colors
  const red = 'rgb(233, 70, 81)'
  const yellow = 'rgb(255, 218, 56)'
  const blue = 'rgb(53, 88, 136)'
  const white = 'rgb(255, 255, 255)'
  // Transitions
  const transitions = {
    must: { delay: 0.75, ease: 'easeOut', duration: 0.75 },
    should: { delay: 1.25, ease: 'easeOut', duration: 0.75 },
    could: { delay: 1.75, ease: 'easeOut', duration: 0.75 },
  }

  const handleAnimationComplete = () => setIntroComplete(true)

  if (introComplete) {
    return null
  }

  return (
    <BoxMotion
      // framer-motion
      animate={{
        opacity: started ? [0, 1, 1, 0] : 0,
      }}
      initial={{
        opacity: 0,
      }}
      onAnimationComplete={handleAnimationComplete}
      transition={{
        ease: 'easeInOut',
        duration: 4,
        times: [0, 0.2, 0.75, 1],
      }}
      // styled props
      alignItems="center"
      bg="#111"
      display="flex"
      justifyContent="center"
      overflow="hidden"
      position="absolute"
      size="100%"
    >
      {/* SVG */}
      <motion.div
        // framer-motion
        animate={{
          scale: started ? 2.0 : 1.0,
          x: started ? '-5%' : '0%',
          y: started ? '50%' : '0%',
        }}
        style={{
          maxWidth: '600px',
          width: '80%',
          height: '80%',
        }}
        transition={{ ease: 'easeInOut', duration: 4 }}
      >
        <svg width="100%" height="100%" viewBox="0 0 596 794">
          <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            {/* Background */}
            <polygon
              fill="#000000"
              points="0 793.701 595.275 793.701 595.275 0 0 0"
            ></polygon>

            {/* Must */}
            <g transform="translate(181.057400, 28.350900)">
              <motion.polygon
                // framer-motion
                animate={{ fill: started ? red : white }}
                initial={{ fill: white }}
                transition={transitions.must}
                // props
                points="-1.42108547e-14 0 43.198 0 66.297 67.797 89.396 0 130.794 0 130.794 112.496 100.796 112.496 100.796 47.698 78.146 112.496 51.447 112.496 28.498 46.798 28.498 112.496 -1.42108547e-14 112.496"
              />
              <motion.path
                d="M221.0947,37.4985 L221.0947,112.4955 L192.5957,112.4955 L192.5957,90.8965 C189.4457,105.8955 182.3967,113.9955 168.2967,113.9955 C154.6477,113.9955 144.5977,106.1955 144.5977,90.4465 L144.5977,37.4985 L173.0967,37.4985 L173.0967,78.1465 C173.0967,86.2465 175.6467,91.4965 182.8457,91.4965 C190.0457,91.4965 192.5957,86.2465 192.5957,77.9975 L192.5957,37.4985 L221.0947,37.4985 Z"
                fill={red}
              />
              <motion.path
                // framer-motion
                animate={{ fill: started ? red : white }}
                initial={{ fill: white }}
                transition={transitions.must}
                // props
                d="M257.0957,58.648 C257.0957,62.848 261.1467,64.648 272.0957,65.998 C293.5447,68.697 301.4937,75.597 301.4937,89.397 C301.4937,104.246 290.9947,113.996 267.8957,113.996 C243.1467,113.996 230.6977,103.346 230.6977,86.097 L256.4957,86.097 C256.4957,92.997 260.9957,96.596 268.4957,96.596 C274.4947,96.596 277.0447,94.497 277.0447,91.046 C277.0447,87.447 273.8957,85.197 260.5457,83.547 C240.1467,80.997 232.6477,74.097 232.6477,60.148 C232.6477,45.749 243.4467,35.999 265.4957,35.999 C285.5947,35.999 300.5947,45.898 300.5947,62.398 L274.7957,62.398 C274.7957,55.798 270.7457,52.948 264.8957,52.948 C259.4957,52.948 257.0957,55.048 257.0957,58.648"
              />
              <motion.path
                // framer-motion
                animate={{ fill: started ? red : white }}
                initial={{ fill: white }}
                transition={transitions.must}
                // props
                d="M367.3427,37.4985 L367.3427,59.9975 L348.4437,59.9975 L348.4437,85.1965 C348.4437,88.3465 349.9437,89.9965 353.2427,89.9965 L366.5927,89.9965 L366.5927,112.4955 L331.4937,112.4955 C325.1947,112.4955 319.9457,108.7455 319.9457,100.6455 L319.9457,59.9975 L305.0957,59.9975 L305.0957,38.9985 C324.4447,38.9985 331.9447,27.7485 331.9447,8.9995 L348.4437,8.9995 L348.4437,37.4985 L367.3427,37.4985 Z"
              />
            </g>
            {/* Should */}
            <g transform="translate(55.058800, 166.851500)">
              <motion.path
                // framer-motion
                animate={{ fill: started ? yellow : white }}
                initial={{ fill: white }}
                transition={transitions.should}
                // props
                d="M48.897,115.495 C18.749,115.495 1.42108547e-14,100.196 1.42108547e-14,72.447 L29.998,72.447 C29.998,86.396 37.498,91.946 49.047,91.946 C57.447,91.946 62.247,88.796 62.247,83.096 C62.247,76.347 56.847,73.197 41.547,70.047 C14.098,64.347 2.999,54.598 2.999,33.448 C2.999,13.049 19.949,0 45.597,0 C71.247,0 90.446,13.499 90.446,38.998 L60.447,38.998 C60.447,28.498 54.597,23.549 45.597,23.549 C37.948,23.549 33.297,26.699 33.297,32.248 C33.297,38.098 37.797,41.398 53.247,44.998 C82.196,51.598 92.696,62.097 92.696,82.346 C92.696,99.745 79.046,115.495 48.897,115.495"
              />
              <motion.path
                // framer-motion
                animate={{ fill: started ? yellow : white }}
                initial={{ fill: white }}
                transition={transitions.should}
                // props
                d="M101.8482,113.995 L101.8482,1.499 L130.3472,1.499 L130.3472,60.597 C133.4962,45.598 140.5462,37.498 154.6462,37.498 C168.2952,37.498 178.3452,45.298 178.3452,61.047 L178.3452,113.995 L149.8462,113.995 L149.8462,73.347 C149.8462,65.247 147.2962,59.997 140.0962,59.997 C132.8972,59.997 130.3472,65.247 130.3472,73.496 L130.3472,113.995 L101.8482,113.995 Z"
              />
              <motion.path
                // framer-motion
                animate={{ fill: started ? yellow : white }}
                initial={{ fill: white }}
                transition={transitions.should}
                // props
                d="M216.148,76.4965 C216.148,90.2955 220.797,98.0955 228.597,98.0955 C236.397,98.0955 240.747,90.2955 240.747,76.4965 C240.747,61.7975 236.397,54.5975 228.597,54.5975 C220.797,54.5975 216.148,61.7975 216.148,76.4965 M269.246,76.4965 C269.246,102.4455 251.247,115.4955 228.447,115.4955 C206.098,115.4955 187.649,102.4455 187.649,76.4965 C187.649,50.5475 206.098,37.4975 228.447,37.4975 C250.946,37.4975 269.246,50.5475 269.246,76.4965"
              />
              <motion.path
                d="M354.8941,38.9979 L354.8941,113.9949 L326.3951,113.9949 L326.3951,92.3959 C323.2451,107.3949 316.1961,115.4949 302.0961,115.4949 C288.4471,115.4949 278.3971,107.6949 278.3971,91.9459 L278.3971,38.9979 L306.8961,38.9979 L306.8961,79.6459 C306.8961,87.7459 309.4461,92.9959 316.6451,92.9959 C323.8451,92.9959 326.3951,87.7459 326.3951,79.4969 L326.3951,38.9979 L354.8941,38.9979 Z"
                fill={yellow}
              />
              <motion.polygon
                // framer-motion
                animate={{ fill: started ? yellow : white }}
                initial={{ fill: white }}
                transition={transitions.should}
                // props
                points="367.4962 113.9955 395.9952 113.9955 395.9952 1.4995 367.4962 1.4995"
              />
              <motion.path
                // framer-motion
                animate={{ fill: started ? yellow : white }}
                initial={{ fill: white }}
                transition={transitions.should}
                // props
                d="M448.4937,96.1459 C455.3937,96.1459 460.9437,89.3959 460.9437,76.6459 C460.9437,63.8969 455.3937,57.1469 448.4937,57.1469 C441.5947,57.1469 436.0447,63.8969 436.0447,76.6459 C436.0447,89.3959 441.5947,96.1459 448.4937,96.1459 L448.4937,96.1459 Z M461.5437,92.5459 C458.6937,106.9449 450.8937,115.4949 436.0447,115.4949 C420.5957,115.4949 405.4457,103.1949 405.4457,76.9469 C405.4457,49.7979 420.5957,37.4979 436.0447,37.4979 C450.8937,37.4979 458.6937,46.0479 461.5437,60.7469 L461.5437,1.4999 L490.0427,1.4999 L490.0427,113.9949 L461.5437,113.9949 L461.5437,92.5459 Z"
              />
            </g>
            {/* Could */}
            <g transform="translate(125.259000, 306.851400)">
              <motion.path
                // framer-motion
                animate={{ fill: started ? blue : white }}
                initial={{ fill: white }}
                transition={transitions.could}
                // props
                d="M83.396,66.147 L111.745,76.197 C105.445,100.496 86.396,115.495 56.997,115.495 C23.249,115.495 -7.10542736e-15,92.546 -7.10542736e-15,57.748 C-7.10542736e-15,23.099 23.249,0 56.997,0 C86.846,0 105.895,15.599 112.044,38.998 L82.796,49.798 C79.646,36.448 70.497,28.499 56.997,28.499 C41.698,28.499 31.348,39.448 31.348,57.748 C31.348,76.197 41.698,86.996 56.997,86.996 C70.796,86.996 79.946,79.046 83.396,66.147"
              />
              <motion.path
                // framer-motion
                animate={{ fill: started ? blue : white }}
                initial={{ fill: white }}
                transition={transitions.could}
                // props
                d="M145.9478,76.4966 C145.9478,90.2956 150.5968,98.0956 158.3968,98.0956 C166.1968,98.0956 170.5468,90.2956 170.5468,76.4966 C170.5468,61.7976 166.1968,54.5976 158.3968,54.5976 C150.5968,54.5976 145.9478,61.7976 145.9478,76.4966 M199.0458,76.4966 C199.0458,102.4456 181.0468,115.4956 158.2468,115.4956 C135.8978,115.4956 117.4488,102.4456 117.4488,76.4966 C117.4488,50.5476 135.8978,37.4976 158.2468,37.4976 C180.7458,37.4976 199.0458,50.5476 199.0458,76.4966"
              />
              <motion.path
                d="M284.6939,38.998 L284.6939,113.995 L256.1949,113.995 L256.1949,92.396 C253.0449,107.395 245.9959,115.495 231.8959,115.495 C218.2469,115.495 208.1969,107.695 208.1969,91.946 L208.1969,38.998 L236.6959,38.998 L236.6959,79.646 C236.6959,87.746 239.2459,92.996 246.4449,92.996 C253.6449,92.996 256.1949,87.746 256.1949,79.497 L256.1949,38.998 L284.6939,38.998 Z"
                fill={blue}
              />
              <motion.polygon
                // framer-motion
                animate={{ fill: started ? blue : white }}
                initial={{ fill: white }}
                transition={transitions.could}
                // props
                points="297.296 113.9956 325.795 113.9956 325.795 1.4996 297.296 1.4996"
              />
              <motion.path
                // framer-motion
                animate={{ fill: started ? blue : white }}
                initial={{ fill: white }}
                transition={transitions.could}
                // props
                d="M378.2935,96.1455 C385.1935,96.1455 390.7435,89.3955 390.7435,76.6465 C390.7435,63.8965 385.1935,57.1475 378.2935,57.1475 C371.3945,57.1475 365.8445,63.8965 365.8445,76.6465 C365.8445,89.3955 371.3945,96.1455 378.2935,96.1455 L378.2935,96.1455 Z M391.3435,92.5455 C388.4935,106.9455 380.6935,115.4955 365.8445,115.4955 C350.3955,115.4955 335.2455,103.1955 335.2455,76.9465 C335.2455,49.7975 350.3955,37.4985 365.8445,37.4985 C380.6935,37.4985 388.4935,46.0475 391.3435,60.7475 L391.3435,1.4995 L419.8425,1.4995 L419.8425,113.9955 L391.3435,113.9955 L391.3435,92.5455 Z"
              />
            </g>
          </g>
        </svg>
      </motion.div>
    </BoxMotion>
  )
}

export default BookCover
