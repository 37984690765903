const UAParser = require('ua-parser-js')

type Browser = {
  minVersion?: number | string
  name: string
}

// Omit `minVersion` to blacklist all browser versions
const UNSUPPORTED_BROWSERS: Browser[] = [
  /*
  {
    name: 'Safari',
    minVersion: '11.1',
  },
  */
]

// This should fail 'optimistically' on any error
// In other words, err on the side of caution and assume the user's browser is supported
export const browserDetect = () => {
  if (typeof window === 'undefined') {
    console.log('Browser detection requires access to the DOM')
    return true
  }

  // Sniff user agent
  let parser
  let currentBrowser: {
    name: string
    version: string
  }

  // Always return true if we're unable to obtain the UA for whatever reason
  try {
    parser = new UAParser()
    currentBrowser = parser.getResult().browser
  } catch {
    return true
  }

  // Ensure that we're using a browser greater than any specified version
  // Note that calling `every` on an empty array will always returns true
  const isSupported = UNSUPPORTED_BROWSERS.every(browser => {
    if (currentBrowser.name === browser.name) {
      return browser.minVersion
        ? currentBrowser.version >= browser.minVersion
        : false
    } else {
      return true
    }
  })

  return isSupported
}
