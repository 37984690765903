import create from 'zustand'
import * as THREE from 'three'

import {
  ArtworkFrame,
  ArtworkVideo,
  ExhibitionSceneName,
  Instruction,
} from '../types'
import { playerApi } from './player'

type IntersectingObject = (ArtworkFrame | ArtworkVideo) & {
  face?: any
  point?: any
}

type InitialState = {
  aboutVisible?: boolean
  // Currently selected artwork (with modal)
  activeArtwork?: ArtworkFrame | ArtworkVideo
  // Current R3F scene
  activeScene?: ExhibitionSceneName
  // Current video element
  activeVideo?: HTMLVideoElement
  activeVideoPlaying?: boolean
  // Count of total (non-unique) artworks viewed
  artworksViewed: number
  // Scene background color - note that this is not glClearColor, simply the
  // background color of the canvas element
  backgroundColor?: string
  collisionList: any[] // TODO: correctly type
  cylinderHeight: number
  cylinderPositionX: number
  cylinderPositionY: number
  cylinderPositionZ: number
  cylinderRadius: number
  cylinderThetaStart: number
  cylinderThetaLength: number
  // DAT.GUI visibility
  debugPanel?: boolean
  hallInitialPosition: [number, number, number]
  hallInitialFocusPoint: THREE.Vector3
  // Light helpers
  lightHelpers?: boolean
  // Current intersecting object
  intersectingObject?: IntersectingObject
  // Intro book animation has finished
  introComplete: boolean
  // Current instruction
  instruction: Instruction
  // User intructions
  instructions: {
    complete: boolean
    type: Instruction
  }[]
  pointLightShadowBias: number
  sceneTransition?: ExhibitionSceneName
  sphereArtwork?: ArtworkVideo
  sphereColor: string
  // User has started interacting (pointer locked at least once)
  started: boolean
  wallsScaleXZ: number
  wallsScaleY: number
}

const initialState: InitialState = {
  aboutVisible: false,
  activeArtwork: undefined,
  activeScene: 'mainHall',
  activeVideo: undefined,
  activeVideoPlaying: false,
  artworksViewed: 0,
  backgroundColor: '#000000',
  collisionList: [],
  cylinderHeight: 4.0,
  cylinderPositionX: -3.584,
  cylinderPositionY: 2.25,
  cylinderPositionZ: 18.948,
  cylinderRadius: 10.155,
  cylinderThetaStart: 2.932,
  cylinderThetaLength: 0.6953,
  debugPanel: false,
  hallInitialPosition: [-18, playerApi.getState().height, 1.88],
  hallInitialFocusPoint: new THREE.Vector3(-11.5, 4, 13.47), // towards interpretation panel
  intersectingObject: undefined,
  introComplete: true, // set as false to display initial book transition
  instruction: 'move',
  instructions: [
    { complete: false, type: 'move' },
    { complete: false, type: 'explore' },
    { complete: false, type: 'wander' },
  ],
  lightHelpers: false,
  pointLightShadowBias: 0.001,
  sphereArtwork: undefined,
  sphereColor: '#0030ff',
  started: false,
  wallsScaleXZ: 1.0,
  wallsScaleY: 1.0,
}

export const [useExhibitionStore, exhibitionApi] = create((set, get) => ({
  ...initialState,
  addToCollisionList: (obj: any) => {
    if (obj) {
      set({
        collisionList: [...get().collisionList, ...[obj]],
      })
    }
  },
  clearActiveArtwork: () => set({ activeArtwork: null }),
  clearActiveVideo: () =>
    set({
      activeVideo: null,
      activeVideoPlaying: false,
    }),
  completeInstruction: (type: Instruction) => {
    // Check if instruction is already completed
    const currentInstructions = get().instructions

    const currentInstructionComplete = currentInstructions.find(
      (instruction: any) => instruction.type === type
    )?.complete

    if (currentInstructionComplete) {
      return
    }

    const updatedInstructions = get().instructions.reduce(
      (acc: any, val: any) => {
        if (val.type === type) {
          val.complete = true
        }
        acc.push(val)
        return acc
      },
      []
    )

    const nextInstruction = updatedInstructions.find(
      (instruction: any) => !instruction.complete
    )?.type

    set({
      instruction: nextInstruction,
      instructions: updatedInstructions,
    })
  },
  setAboutVisible: (aboutVisible: boolean) => set({ aboutVisible }),
  setActiveVideo: (activeVideo: HTMLVideoElement) =>
    set({ activeVideo, activeVideoPlaying: false }),
  setActiveVideoPlaying: (activeVideoPlaying: boolean) =>
    set({ activeVideoPlaying }),
  setActiveArtwork: (activeArtwork: ArtworkFrame | ArtworkVideo) => {
    set({
      activeArtwork,
      artworksViewed: get().artworksViewed + 1,
    })
  },
  setActiveScene: (activeScene: ExhibitionSceneName) => {
    set({
      activeScene,
      collisionList: [],
      sceneTransition: null,
    })
  },
  setBackgroundColor: (color: string) =>
    set({
      backgroundColor: color,
    }),
  setData: (data: any) => set({ ...data }),
  setIntersectingObject: (intersectingObject: IntersectingObject) =>
    set({ intersectingObject }),
  setIntroComplete: (introComplete: boolean) =>
    set({
      introComplete,
    }),
  setPointLightShadowBias: (bias: number) =>
    set({
      pointLightShadowBias: bias,
    }),
  setSceneTransition: (targetScene: ExhibitionSceneName) => {
    set({
      sceneTransition: targetScene,
    })
  },
  setSphereArtwork: (sphereArtwork: ArtworkVideo) => set({ sphereArtwork }),
  setStarted: () => {
    set({
      started: true,
    })
  },
  setWallsScaleXZ: (val: number) =>
    set({
      wallsScaleXZ: val,
    }),
  setWallsScaleY: (val: number) =>
    set({
      wallsScaleY: val,
    }),
  toggleDebugPanel: () => set({ debugPanel: !get().debugPanel }),
  toggleLightHelpers: () => set({ lightHelpers: !get().lightHelpers }),
}))
