import React, { ReactNode } from 'react'
import styled, { css } from 'styled-components'

import { useStore } from '../../store'
import { BoxProps } from '../../types'
import Box from '../Box'
import IconClose from '../IconClose'

type Props = BoxProps & {
  blurBackground?: boolean
  children?: ReactNode
  onClose?: () => void
  onClick?: () => void
}

const Container = styled(Box)<{
  blurBackground?: boolean
  customCursor?: boolean
}>`
  backdrop-filter: ${props => (props.blurBackground ? 'blur(20px)' : 'none')};

  ${props =>
    props.customCursor &&
    css`
      cursor: url('/cursors/close.png') 0 0, auto; /* Legacy */
      cursor: -webkit-image-set(
            url('/cursors/close.png') 1x,
            url('/cursors/close@2x.png') 2x
          )
          15 15,
        pointer; /* Webkit */
    `}
`

const ScreenWrapper = (props: Props) => {
  const {
    blurBackground = false,
    children,
    onClick,
    onClose,
    ...boxProps
  } = props

  // Store (global)
  const controlScheme = useStore(state => state.controlScheme)

  const fixedClose = controlScheme === 'touch'

  return (
    <Container
      alignItems="center"
      blurBackground={blurBackground}
      color="white"
      customCursor={!fixedClose && onClose}
      display="flex"
      fontSize={3}
      justifyContent="center"
      left={0}
      onClick={!fixedClose && onClose ? onClose : undefined}
      position="fixed"
      size="100%"
      top={0}
      {...boxProps}
    >
      {children}

      {/* Fixed close button - only visible if PL is not supported */}
      {fixedClose && onClose && (
        <Box
          className="hover-opacity"
          cursor="pointer"
          onClick={onClose ? onClose : undefined}
          position="fixed"
          right={[3, 3, 4]}
          size={['20px', '20px', '30px']}
          top={[3, 3, 4]}
          zIndex="screen"
        >
          <IconClose />
        </Box>
      )}
    </Container>
  )
}

export default ScreenWrapper
