import React, { useEffect, useRef } from 'react'
import { useLoader } from 'react-three-fiber'
import { ReactThreeFiber } from 'react-three-fiber/three-types'
import * as THREE from 'three'

import { useExhibitionStore } from '../../store/exhibition'
import { ArtworkFrame } from '../../types'

type Props = ReactThreeFiber.Object3DNode<THREE.Mesh, typeof THREE.Mesh> &
  ArtworkFrame & {}

const FrameWithAlpha = (props: Props) => {
  const { detail, image, mesh: meshProps } = props

  const mesh = useRef<THREE.Mesh>()

  // Store (exhibition)
  const addToCollisionList = useExhibitionStore(
    state => state.addToCollisionList
  )

  // Load texture
  const texture = useLoader(THREE.TextureLoader, image.src)
  texture.anisotropy = 4
  texture.encoding = THREE.sRGBEncoding

  const textureAlphaMap = useLoader(THREE.TextureLoader, image.alphaMap || '')
  textureAlphaMap.anisotropy = 4
  textureAlphaMap.encoding = THREE.sRGBEncoding

  useEffect(() => {
    // Add to collision table
    addToCollisionList(mesh?.current)

    // Create materials array
    const materialCanvas = new THREE.MeshBasicMaterial({
      color: 0xcccccc,
    })
    const materialTexture = new THREE.MeshBasicMaterial({
      alphaMap: textureAlphaMap,
      map: texture,
      ...(image.transparent
        ? {
            transparent: image.transparent,
          }
        : {}),
    })

    const boxMaterials = [
      materialCanvas,
      materialCanvas,
      materialCanvas,
      materialCanvas,
      materialTexture, // Front
      materialCanvas,
    ]

    if (mesh.current) {
      // Conditionally assign either a texture array or single texture
      mesh.current.material = meshProps.extrude ? boxMaterials : materialTexture
    }
  }, [])

  const width = meshProps.height * image.aspectRatio

  return (
    <>
      {/* Frame */}
      <mesh
        ref={mesh}
        userData={{
          action: 'artworkFrame',
          detail,
          image,
        }}
        {...meshProps}
      >
        {meshProps.extrude ? (
          <boxBufferGeometry
            attach="geometry"
            args={[width, meshProps.height, meshProps.extrude]}
          />
        ) : (
          <planeBufferGeometry
            attach="geometry"
            args={[width, meshProps.height]}
          />
        )}
      </mesh>
    </>
  )
}

export default FrameWithAlpha
