import { api } from '../store'
import { exhibitionApi } from '../store/exhibition'

export default function () {
  const controlScheme = api.getState().controlScheme
  const {
    activeScene,
    artworksViewed,
    completeInstruction,
    intersectingObject,
    setAboutVisible,
    setActiveArtwork,
    setSceneTransition,
    setSphereArtwork,
  } = exhibitionApi.getState()

  const intersectingObjectAction = intersectingObject?.action

  if (['imageCylinder', 'imageSphere', 'videoSphere'].includes(activeScene)) {
    // Transition to new scene
    setSceneTransition('mainHall')
    return
  }

  if (intersectingObjectAction === 'artworkFrame') {
    // TODO: move elsewhere
    // Mark 'explore' instruction as complete
    if (artworksViewed > 2) {
      completeInstruction('explore')
    }

    if (controlScheme === 'pointerLock') {
      document.exitPointerLock()
    }

    setActiveArtwork(intersectingObject)
    return
  }

  if (
    intersectingObjectAction === 'enterImageCylinder' ||
    intersectingObjectAction === 'enterImageSphere' ||
    intersectingObjectAction === 'enterVideoSphere'
  ) {
    // Update sphere artwork detail
    setSphereArtwork(intersectingObject)

    // Transition to new scene
    if (intersectingObjectAction === 'enterImageCylinder') {
      setSceneTransition('imageCylinder')
    }

    if (intersectingObjectAction === 'enterImageSphere') {
      setSceneTransition('imageSphere')
    }

    if (intersectingObjectAction === 'enterVideoSphere') {
      setSceneTransition('videoSphere')
    }

    return
  }

  if (intersectingObjectAction === 'about') {
    if (controlScheme === 'pointerLock') {
      document.exitPointerLock()
    }

    // Show about page
    setAboutVisible(true)

    return
  }
}
