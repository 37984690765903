import React from 'react'
import { useLoader } from 'react-three-fiber'
import * as THREE from 'three'

import { useExhibitionStore } from '../../store/exhibition'
import offsetTexture from '../../utils/offsetTexture'

type Props = {
  radius: number
}

const ImageCylinder = (props: Props) => {
  const { radius } = props

  // Store (exhibition)
  const sphereArtwork = useExhibitionStore(state => state.sphereArtwork)

  const { image, mesh: meshProps } = sphereArtwork

  const texture = useLoader(THREE.TextureLoader, image?.src)
  texture.anisotropy = 4
  texture.encoding = THREE.sRGBEncoding

  // Offset texture for non power-of-two aspect destination
  offsetTexture(texture, image?.aspectRatio, meshProps?.aspectRatio)

  return (
    <>
      {/* Black sphere */}
      <mesh scale={[-1, 1, 1]}>
        <cylinderBufferGeometry
          args={[radius, radius, 54, 48, 1, true]}
          attach="geometry"
        />
        <meshBasicMaterial
          attach="material"
          map={texture}
          side={THREE.BackSide}
        />
      </mesh>
    </>
  )
}

export default ImageCylinder
