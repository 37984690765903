import React from 'react'

import BoxMotion from '../BoxMotion'
import LogoFF from '../LogoFF'
import LogoHato from '../LogoHato'

type Props = {}

const Footer = () => {
  return (
    <BoxMotion
      // framer-motion
      animate={{
        opacity: 1,
      }}
      initial={{
        opacity: 0,
      }}
      transition={{
        delay: 3,
        ease: 'easeInOut',
        duration: 1.25,
      }}
      // styled-props
      position="fixed"
      zIndex="footer"
    >
      <LogoFF
        bottom={[3, 3, 4]}
        left={[3, 3, 4]}
        position="fixed"
        width={['80px', '80px', '100px']}
      />
      <LogoHato
        bottom={[3, 3, 4]}
        position="fixed"
        right={[3, 3, 4]}
        width={['64px', '64px', '80px']}
      />
    </BoxMotion>
  )
}

export default Footer
