import { system } from 'styled-system'
import styled from 'styled-components'

import Box from '../Box'

const TextOutline = styled(Box)`
  ${system({
    // @ts-ignore
    textStrokeColor: {
      property: '-webkit-text-stroke-color',
      scale: 'colors',
    },
  })}

  color: inherit;

  @supports (-webkit-text-stroke: 0.5px black) {
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke-width: 0.5px;
    /* NOTE: stroke color is applied with 'system' above, and uses the colors scale */
  }
`

TextOutline.defaultProps = {
  as: 'span',
  fontWeight: 'bold',
}

export default TextOutline
