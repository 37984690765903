import { AnimatePresence } from 'framer-motion'
import React from 'react'

import { useStore } from '../../store'
import { useExhibitionStore } from '../../store/exhibition'
import { usePlayerStore } from '../../store/player'
import Box from '../Box'
import BoxMotion from '../BoxMotion'
import InstructionProgress from '../InstructionProgress'
import ScreenWrapper from '../ScreenWrapper'

type Props = {}

const ScreenLock = () => {
  // Store (global)
  const controlScheme = useStore(state => state.controlScheme)
  // Store (exhibition)
  const aboutVisible = useExhibitionStore(state => state.aboutVisible)
  const activeArtwork = useExhibitionStore(state => state.activeArtwork)
  // Store (player)
  const pointerLocked = usePlayerStore(state => state.pointerLocked)

  const handleClose = () => {
    if (controlScheme === 'pointerLock') {
      document.body.requestPointerLock()
    }
  }

  // Display
  const visible =
    controlScheme === 'pointerLock' &&
    !pointerLocked &&
    !activeArtwork &&
    !aboutVisible

  return (
    <AnimatePresence>
      {visible && (
        <BoxMotion
          // framer-motion
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          initial={{ opacity: 0 }}
          // styled props
          position="fixed"
          zIndex="screen"
        >
          <ScreenWrapper
            bg="rgba(0, 0, 0, 0.8)"
            blurBackground={true}
            display="flex"
            flexDirection="column"
            onClose={handleClose}
          >
            <InstructionProgress />

            <Box fontSize={0} mt={[3, 3, 4]} textAlign="center">
              <Box>Design and build by HATO</Box>
            </Box>
          </ScreenWrapper>
        </BoxMotion>
      )}
    </AnimatePresence>
  )
}

export default ScreenLock
