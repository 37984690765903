import React from 'react'

import { ArtworkDetail, BoxProps } from '../../types'
import Box from '../Box'
import TextOutline from '../TextOutline'

type Props = BoxProps & {
  detail: ArtworkDetail
  variant?: 'dark' | 'light'
}

const ArtworkDescription = (props: Props) => {
  const { detail, variant = 'dark', ...boxProps } = props

  const longArtistTitle = detail?.artist?.length > 40

  return (
    <Box
      height="auto"
      maxWidth="1080px"
      mx="auto"
      px={4}
      textAlign="center"
      width="100%"
      {...boxProps}
    >
      {/* Category */}
      {detail?.category && (
        <Box color={variant === 'dark' ? 'red' : 'white'} fontSize={[0, 0, 1]}>
          {detail.category}
        </Box>
      )}

      {/* Artist */}
      {detail?.artist && (
        <TextOutline
          display="inline-block"
          fontSize={longArtistTitle ? [1, 1, 2] : [3, 3, 4]}
          fontWeight="bold"
          mt={[1, 1, 2]}
          textStrokeColor={variant === 'dark' ? 'black' : 'white'}
        >
          {detail.artist}
        </TextOutline>
      )}

      {/* Caption */}
      {detail?.caption && (
        <Box
          color={variant === 'dark' ? 'black' : 'white'}
          fontSize={[0, 0, 1]}
          mt={[1, 1, 2]}
        >
          {detail.caption}
        </Box>
      )}
    </Box>
  )
}

export default ArtworkDescription
