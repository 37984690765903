import { useRef, useEffect, useMemo } from 'react'
import { useFrame, useThree } from 'react-three-fiber'
import * as THREE from 'three'

import { useStore, api } from '../../store'
import { exhibitionApi, useExhibitionStore } from '../../store/exhibition'
import { playerApi, usePlayerStore } from '../../store/player'

const euler = new THREE.Euler(0, 0, 0, 'YXZ')
const vec = new THREE.Vector3()

// Required distance to move before 'move' instruction is completed
const MOVE_DISTANCE_THRESHOLD = 500

const Player = () => {
  // Store (global)
  const controlScheme = useStore(state => state.controlScheme)
  const viewActive = useStore(state => state.viewActive)
  const viewTransition = useStore(state => state.viewTransition)
  // Store (exhibition)
  const introComplete = useExhibitionStore(state => state.introComplete)
  const collisionList = useExhibitionStore(state => state.collisionList)
  const completeInstruction = useExhibitionStore(
    state => state.completeInstruction
  )
  const sceneTransition = useExhibitionStore(state => state.sceneTransition)
  // Store (player)
  const freeMovement = usePlayerStore(state => state.freeMovement)
  const heldInPlace = usePlayerStore(state => state.heldInPlace)
  const playerFov = usePlayerStore(state => state.fov)
  const playerHeight = usePlayerStore(state => state.height)
  const playerMovementSpeed = usePlayerStore(state => state.movementSpeed)
  const running = usePlayerStore(state => state.running)
  const setBookmarkedFocusPoint = usePlayerStore(
    state => state.setBookmarkedFocusPoint
  )
  const setBookmarkedPosition = usePlayerStore(
    state => state.setBookmarkedPosition
  )

  const { camera, raycaster: defaultRaycaster } = useThree()

  const distanceTravelled = useRef<number>(0)
  const moveCompleted = useRef<boolean>(false)

  const lookSpeedModifier = useMemo(() => {
    if (controlScheme === 'pointerLock') {
      return 0.002
    }
    if (controlScheme === 'touch') {
      return 0.004
    }
    console.warn('No valid control scheme detected')
    return 1
  }, [])

  const movementSpeedModifier = useMemo(() => {
    if (controlScheme === 'pointerLock') {
      return 1
    }
    if (controlScheme === 'touch') {
      return 1.4
    }
    console.warn('No valid control scheme detected')
    return 1
  }, [])

  // Vectors, scene refs
  const inputVector = useMemo(() => new THREE.Vector3(0, 0, 0), [])
  const velocity = useMemo(() => new THREE.Vector3(), [])
  const movingBackward = useRef<any>(false)
  const movingForward = useRef<any>(false)
  const movingLeft = useRef<any>(false)
  const movingRight = useRef<any>(false)

  // Update camera projection matrix on FOV changes
  playerApi.subscribe(
    () => {
      camera.updateProjectionMatrix()
    },
    state => state.fov
  )

  const look = (lookDelta: { x: number; y: number } | null) => {
    if (lookDelta) {
      euler.setFromQuaternion(camera.quaternion)
      euler.y -= lookDelta.x * lookSpeedModifier
      euler.x -= lookDelta.y * lookSpeedModifier
      euler.x = Math.max(-(Math.PI / 2), Math.min(Math.PI / 2, euler.x))

      camera.quaternion.setFromEuler(euler)
    }
  }

  // Move forward parallel to the xz-plane
  // assumes camera.up is y-up
  const moveForward = (distance: number) => {
    vec.setFromMatrixColumn(camera.matrix, 0)
    vec.crossVectors(camera.up, vec)
    camera.position.addScaledVector(vec, distance)
  }

  const moveRight = (distance: number) => {
    vec.setFromMatrixColumn(camera.matrix, 0)
    camera.position.addScaledVector(vec, distance)
  }

  useFrame((state, delta) => {
    // Update camera
    // @ts-ignore
    camera.fov = playerFov
    if (!freeMovement) {
      camera.position.y = playerHeight
    }

    // Prevent movement on the following conditions
    if (
      !introComplete ||
      sceneTransition ||
      viewActive !== 'exhibition' ||
      viewTransition
    ) {
      return
    }

    // Apply movement speed modifiers (running)
    let movementSpeed = playerMovementSpeed * movementSpeedModifier
    if (running) {
      movementSpeed = movementSpeed * 4
    }
    if (freeMovement) {
      movementSpeed = 500
    }
    if (heldInPlace) {
      movementSpeed = 0
    }

    // Apply inertia
    velocity.x -= velocity.x * 10.0 * delta
    velocity.z -= velocity.z * 10.0 * delta

    // Apply ray to camera
    defaultRaycaster.setFromCamera(new THREE.Vector2(0, 0), camera)

    // Apply direction forces
    inputVector.x = -Number(movingLeft.current) + Number(movingRight.current)
    inputVector.z =
      -Number(movingForward.current) + Number(movingBackward.current)
    inputVector.normalize()
    if (movingForward.current || movingBackward.current) {
      velocity.z += inputVector.z * movementSpeed * delta
    }
    if (movingLeft.current || movingRight.current) {
      velocity.x += inputVector.x * movementSpeed * delta
    }

    // Collision detection
    // TODO: fix this - currently raycasting, consider using a texture map instead
    // Also consider abstracting out
    inputVector.applyQuaternion(camera.quaternion)

    if (inputVector.lengthSq() > 0) {
      const ray = new THREE.Raycaster(
        camera.position,
        velocity.clone().applyQuaternion(camera.quaternion).normalize()
      )

      // Only enable raycasting when freeMovement is disabled
      const intersects = freeMovement ? [] : ray.intersectObjects(collisionList)

      if (intersects.length > 0 && intersects[0].distance < 1) {
        velocity.set(0, 0, 0)
      }
    }

    distanceTravelled.current += velocity.lengthSq()

    // Complete move instruction after a certain amount of distance has been travelled
    if (
      distanceTravelled.current > MOVE_DISTANCE_THRESHOLD &&
      !moveCompleted.current
    ) {
      completeInstruction('move')

      moveCompleted.current = true
    }

    // Move player
    moveRight(velocity.x * delta)
    if (freeMovement) {
      camera.translateZ(velocity.z * delta)
    } else {
      moveForward(-velocity.z * delta)
    }
  })

  useEffect(() => {
    // Subscribe to transition changes
    exhibitionApi.subscribe(
      sceneTransition => {
        // console.log('Player (world-sceneTransition)', sceneTransition)
        if (
          sceneTransition === 'imageCylinder' ||
          sceneTransition === 'imageSphere' ||
          sceneTransition === 'videoSphere'
        ) {
          // Calculate the current 'look point', make sure to
          // add the current camera's position vector as well
          const vector = new THREE.Vector3(0, 0, -1) // Camera's internal negative z-axis
          vector.applyQuaternion(camera.quaternion)
          vector.add(camera.position)
          setBookmarkedFocusPoint(vector.clone())
          // Mark previous position
          setBookmarkedPosition(camera.position.clone())
        }
      },
      state => state.sceneTransition
    )

    // Subscribe to look delta changes (mouse / touch look)
    playerApi.subscribe(
      (lookDelta: any) => look(lookDelta),
      state => state.lookDelta
    )

    // Subscribe to movement state changes (WASD / arrow keys)
    // prettier-ignore
    playerApi.subscribe(val => (movingForward.current = val), state => state.movement.forward)
    // prettier-ignore
    playerApi.subscribe(val => (movingBackward.current = val), state => state.movement.backward)
    // prettier-ignore
    playerApi.subscribe(val => (movingLeft.current = val), state => state.movement.left)
    // prettier-ignore
    playerApi.subscribe(val => (movingRight.current = val), state => state.movement.right)

    // Subscribe to view changes
    api.subscribe(
      viewActive => {
        if (viewActive !== 'exhibition') {
          playerApi.setState({
            movement: {
              backward: false,
              forward: false,
              left: false,
              right: false,
            },
          })
        }
      },
      state => state.viewActive
    )
  }, [])

  return null
}

export default Player
