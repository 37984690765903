// Offset a power-of-two texture for use on a non power-of-two aspect destination
// NOTE: This mutates `texture`
import * as THREE from 'three'

const OVERBLEED = 0.01

const offsetTexture = (
  texture: THREE.Texture,
  imageAspectRatio: number,
  destAspectRatio: number
) => {
  if (destAspectRatio >= imageAspectRatio) {
    const repeatY = imageAspectRatio / destAspectRatio - OVERBLEED
    texture.repeat.y = repeatY
    texture.offset.y = (1 - repeatY) * 0.5 // assuming centered texture
  } else {
    const repeatX = destAspectRatio / imageAspectRatio - OVERBLEED
    texture.repeat.x = repeatX
    texture.offset.x = (1 - repeatX) * 0.5 // assuming centered texture
  }
}

export default offsetTexture
