import React from 'react'
import styled from 'styled-components'

import { BoxProps } from '../../types'
import Box from '../Box'

type Props = BoxProps & {}

const Container = styled(Box)`
  svg {
    width: 100%;
    height: auto;
  }
`

const LogoHato = (props: Props) => {
  const { ...boxProps } = props

  return (
    <Container className="hover-opacity" {...boxProps}>
      <a href="https://hato.co" rel="noopener noreferrer" target="_blank">
        <svg
          height="16.91"
          preserveAspectRatio="xMidYMid meet"
          viewBox="0 0 77.89 16.91"
          width="77.89"
        >
          <path
            d="M69.52,2.36a5.84,5.84,0,0,1,5.67,6.1,5.69,5.69,0,1,1-11.35,0,5.86,5.86,0,0,1,5.68-6.1m0,14.55a8.46,8.46,0,1,0-8.38-8.45,8.42,8.42,0,0,0,8.38,8.45M50.21,2.5H55V.22H43V2.5h4.79V16.71h2.45ZM27.57,10.59l3.05-7.22,3.09,7.22Zm-1.08,2.34h8.3l1.77,3.78h2.56L31.57.22H29.68L22.17,16.71h2.54ZM15,.22H12.5V6.61h-10V.22H0V16.71H2.47V9h10v7.69H15Z"
            fill="white"
          />
        </svg>
      </a>
    </Container>
  )
}

export default LogoHato
