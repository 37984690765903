import React from 'react'
import styled from 'styled-components'

import Box from '../Box'
import TextOutline from '../TextOutline'

type Props = {}

const ColumnContainer = styled(Box)`
  p:first-child {
    margin-top: 0;
  }
`

const Gradient = styled(Box)`
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100px;
  pointer-events: none;

  /* TODO: Consider manually interpolating */
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 1) 0%,
    rgba(0, 0, 0, 1) 30%,
    rgba(0, 0, 0, 0) 100%
  );
`

const About = (props: Props) => {
  return (
    <>
      {/* Gradient */}
      <Gradient />

      <TextOutline
        display="block"
        fontSize={[5, 5, 6]}
        fontWeight="bold"
        left="50%"
        top={3}
      >
        About
      </TextOutline>
      <Box
        alignItems={['flex-start', 'flex-start', 'center']}
        // bg="orange"
        display="flex"
        fontSize={[1, 1, 1, 1, 2]}
        height="100%"
        justifyContent="center"
        minHeight={['auto', 'auto', '600px']}
        mt={[5, 5, 0]}
        mx={2}
      >
        <ColumnContainer
          columnCount={[1, 1, 3]}
          lineHeight="body"
          maxWidth="1300px"
          pb={[7, 7, 0]}
        >
          <p>
            <TextOutline>Must Should Could </TextOutline> is the result of a
            collaboration between artist teachers on the Art & Design PGCE
            course at the Institute of Education, University College London and
            Freelands Foundation. <TextOutline>Must Should Could </TextOutline>{' '}
            is the culmination of this project and is the result of the fifth
            year of this partnership. Each year we have explored the notion of
            the artist teacher, the relationship between training to teach and
            to maintaining a creative practice.
          </p>
          <p>
            <TextOutline>Must Should Could </TextOutline> focuses on
            instruction. As a form of educational communication, instruction is
            located at the intersection of command, control and collaboration.
          </p>
          <p>
            Teaching inevitably involves the issuing of instructions, but is
            also about following them. This potential for personal and
            professional conflict is especially pronounced for PGCE artist
            teachers, as they negotiate between their creative practices,
            university perspectives and school priorities to forge their
            identities as teachers.
          </p>
          <p>
            <TextOutline>Must Should Could </TextOutline> refers to an
            often-used technique from the school classroom, which provides a
            framework in which pupils are instructed to complete an essential
            task, encouraged to do better or advised to aim to achieve more.
            ‘Must’ could be perceived as passive aggressive, ‘Should’ might
            infer a lack of commitment, whereas ‘Could’ relaxes into a more
            flirtatious possibility of going a step further.
          </p>
          <Box breakBefore={['auto', 'auto', 'column']}>
            <p>
              <TextOutline>Curated by</TextOutline> Henry Ward
            </p>
            <p>
              <TextOutline>Design and Digital Production</TextOutline> HATO
            </p>
            <p>
              <TextOutline>Participating Artists</TextOutline> Harifa Ahmed,
              Iqra Ahmed, Lucy Bradley, Klara Choromanska, Emma Close, Holly
              Coupland, Katie Duke, Eppie Greenhalgh, Isobel Harvey, Lisa
              Haslinger, Lisa Haslinger, Alice Horn, Rebecca Hudson, Jordan
              Hughes, Rebecca Hurn, Kerry McGranaghan, Jazmyn Maher, Jenelle
              Marfo, Frances Marriott, Helena Maxwell-Johnson, Linnea Nilsson,
              Anna Rogers, Anoushka Seewoogoolam, Chloe Shirley, Molly Skinner,
              Holly Stenhouse, Christy Waddell, Laura Wend and Rachel Wilcock
            </p>
          </Box>
        </ColumnContainer>
      </Box>
    </>
  )
}

export default About
