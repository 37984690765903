import React from 'react'

type Props = {
  variant?: 'dark' | 'light'
}

const IconWASD = (props: Props) => {
  const { variant } = props

  return (
    <svg
      viewBox="0 0 21.41 14.5"
      style={{
        display: 'block',
        width: '100%',
        height: 'auto',
      }}
    >
      <g fill={variant === 'dark' ? 'black' : 'white'}>
        <path d="M10.83.74l-1.18,4H9.1L7.73,0h.55L9.4,3.87,10.55,0h.58l1.21,3.87L13.41,0h.53L12.59,4.74H12Z" />
        <g>
          <path d="M4.2,14.44H3.64L3.08,13h-2L.52,14.44H0L1.78,9.7h.6ZM2.91,12.52l-.85-2.25-.83,2.25Z" />
          <path d="M10.75,14.5A1.65,1.65,0,0,1,9,13.21l.48-.15a1.2,1.2,0,0,0,1.3,1c.67,0,1.07-.31,1.07-.76s-.26-.71-1.22-1-1.49-.68-1.49-1.39.63-1.27,1.54-1.27a1.49,1.49,0,0,1,1.6,1.26l-.45.19a1.06,1.06,0,0,0-1.15-1c-.65,0-1,.33-1,.77s.2.68,1.2,1,1.51.69,1.51,1.41S11.81,14.5,10.75,14.5Z" />
          <path d="M19.06,9.7A2.12,2.12,0,0,1,21.41,12a2.17,2.17,0,0,1-2.31,2.42H17.69V9.7ZM20.87,12a1.65,1.65,0,0,0-1.75-1.88H18.2V14h.92C20.43,14,20.87,13.13,20.87,12Z" />
        </g>
      </g>
    </svg>
  )
}

export default IconWASD
