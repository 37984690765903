import React, { useRef, useEffect } from 'react'
import { useFrame, useLoader } from 'react-three-fiber'
import { ReactThreeFiber } from 'react-three-fiber/three-types'
import * as THREE from 'three'

import { useExhibitionStore } from '../../store/exhibition'
import { ArtworkCone } from '../../types'

type Props = ReactThreeFiber.Object3DNode<THREE.Mesh, typeof THREE.Mesh> &
  ArtworkCone

const Cone = (props: Props) => {
  const { detail, mesh: meshProps, planes } = props

  // Store (exhibition)
  const addToCollisionList = useExhibitionStore(
    state => state.addToCollisionList
  )

  const mesh = useRef<THREE.Mesh>()

  // Load texture
  const texture = useLoader(
    THREE.TextureLoader,
    '/textures/cone/512x512_q60.jpg'
  )
  texture.anisotropy = 4
  texture.encoding = THREE.sRGBEncoding
  texture.repeat.set(3, 3)
  texture.wrapS = THREE.RepeatWrapping
  texture.wrapT = THREE.RepeatWrapping

  useFrame(state => {
    if (mesh.current) {
      const time = state.clock.getElapsedTime()
      mesh.current.position.y =
        meshProps.position[1] + 0.5 * (0.5 * Math.sin(time) + 1)
    }
  })

  // Add to collision table
  useEffect(() => {
    addToCollisionList(mesh?.current)
  }, [])

  return (
    <mesh
      castShadow={true}
      ref={mesh}
      userData={{
        action: 'enterImageSphere',
        detail,
        planes,
      }}
      {...meshProps}
    >
      <coneBufferGeometry args={[1, 4, 36]} attach="geometry" />
      <meshBasicMaterial attach="material" color={0xdadada} map={texture} />
    </mesh>
  )
}

export default Cone
