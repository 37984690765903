import React from 'react'
import styled from 'styled-components'

import { useStore } from '../../store'
import { BoxProps, Instruction } from '../../types'
import Box from '../Box'
import IconWASD from '../IconWASD'

type Props = BoxProps & {
  instruction?: Instruction
  outline?: boolean
  variant?: 'light' | 'dark'
}

const IconContainer = styled(Box)`
  display: inline-block;

  svg {
    display: inline-block;
    /* width: 100%; */
    height: auto;
  }
`

const Pill = (props: Props) => {
  const { instruction, outline, variant = 'dark', ...boxProps } = props

  // Store (global)
  const controlScheme = useStore(state => state.controlScheme)

  const bg = variant === 'dark' ? 'black' : 'white'
  const borderColor = variant === 'dark' ? 'white' : 'black'

  return (
    <Box
      bg={outline ? 'transparent' : bg}
      borderColor={outline ? borderColor : 'transparent'}
      borderStyle="solid"
      borderWidth="1px"
      borderRadius="pill"
      color={variant === 'dark' ? 'white' : 'black'}
      display="inline-flex"
      fontSize={[0, 0, 2]}
      letterSpacing="tight"
      lineHeight="body"
      px={[2, 2, 3]}
      py="3px"
      {...boxProps}
    >
      {/* Complete */}
      {!instruction && <span>Complete</span>}

      {/* Move (Pointer lock) */}
      {instruction === 'move' && controlScheme === 'pointerLock' && (
        <span>
          You{' '}
          <Box color="yellow" display="inline">
            must
          </Box>{' '}
          use your mouse and the
          <IconContainer
            mb="1px"
            mx="0.4em"
            width={['21px', '21px', '30px']}
            verticalAlign="sub"
          >
            <IconWASD variant={variant === 'dark' ? 'light' : 'dark'} />
          </IconContainer>
          keys to navigate
        </span>
      )}

      {/* Move (Touch) */}
      {instruction === 'move' && controlScheme === 'touch' && (
        <span>
          You{' '}
          <Box color="yellow" display="inline">
            must
          </Box>{' '}
          walk around by pressing firmly down on your screen
        </span>
      )}

      {/* Explore */}
      {instruction === 'explore' && (
        <span>
          You{' '}
          <Box color="red" display="inline">
            should
          </Box>{' '}
          explore the whole room
        </span>
      )}

      {/* Wander */}
      {instruction === 'wander' && (
        <span>
          You{' '}
          <Box color="blue" display="inline">
            could
          </Box>{' '}
          wander off into the abyss
        </span>
      )}
    </Box>
  )
}

export default Pill
